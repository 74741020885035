import React from "react";
import { Link } from "react-router-dom";

import styles from "./politicadetratamientodedatos.module.css";

/**
 * Politicadetratamientodedatos component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Politicadetratamientodedatos component.
 */
const Politicadetratamientodedatos = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Politicadetratamientodedatos}>
        <section className={styles.politicadetratamientodedatosheader}>
          <Link to="/Home">
            <section
              className={styles["layer-192-8c467f7b9f0440b29ac550959d51f043"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-332-85635280a723408c88edbea32ff24de9"]
                  }
                  alt="imageview-332"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-193-dcda1da104f1453cb34ffd9b57edf130"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-182-298104f6ee594404a4189c85817a40f6"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-183-7b539479ed0340208ff0ed3d3a4e6a14"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-184-30156efb28084180bba7084cff0ddeb4"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-185-c9bf7ba79f88477486a26dd4c7601733"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-194-936c6be7b01a47aa946b3510c80b6b6e"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-186-cdd5afdacc0e4267ab9b67e61ca7e14c"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-195-1c13e243f0da4659b6aa14f8994ae82b"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-11-3eebdb6485c742199ec9097dbcfe6c8e"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section className={styles.politicadetratamientodedatossection1}>
          <p className={styles["text-189-2e0a115504ba427e8e0b4d9f2eaef968"]}>
            Política de Tratamiento de Datos Personales BAVIERA LEGAL “BAVIERA”
          </p>
          <p className={styles["text-190-f9eaa17f4cdd41129f0c9a73b0736cec"]}>
            De acuerdo con lo establecido en el artículo 9º de la Ley Estatuaria
            1581 de 2012 y con los artículos 5, 6 y 7 del Decreto 1377 de 2013,
            le informamos que los datos personales recaudados por BAVIERA LEGAL
            en adelante “BAVIERA”, serán objeto de recolección, almacenamiento,
            uso y circulación en los términos establecidos en el presente
            documento.{" "}
          </p>
          <p className={styles["text-191-23946aa6fcf3406b8cf7a993648adc29"]}>
            1. Identificación del Responsable del Tratamiento de la Información:
            BAVIERA LEGAL “BAVIERA”, Carrera 9 N°115-06/30 Piso 17, Tierra Firme
            Complex, Bogotá D.C., Colombia, página web www.bavieralegal.com, y
            teléfono (+57 1) 7495506.
          </p>
          <p className={styles["text-192-cb65fd544dd94bd9888a52ffc5b2969b"]}>
            2. Finalidades y Tratamiento al cual serán sometidos los Datos
            Personales: Los datos personales por usted proporcionados a BAVIERA
            serán almacenados en las bases de datos de BAVIERA y serán
            utilizados para alguna de las siguientes finalidades:
          </p>
          <p className={styles["text-193-154c0227f4e446429ed603e2a0d262b9"]}>
            a) Respecto de clientes de BAVIERA, el tratamiento de los datos se
            realizará con la finalidad de poder prestar los servicios
            contratados consistentes en asesoría y representación legal en
            asuntos jurídicos de diversa índole por parte de BAVIERA, así como
            informarle acerca de novedades o noticias regulatorias que BAVIERA
            realiza para mantener actualizados e informados a sus clientes en
            asuntos de su interés.
          </p>
          <p className={styles["text-194-a513b368f6e84711b1850c04a202fa7c"]}>
            b) Respecto de los empleados de BAVIERA, el tratamiento de los datos
            se realizará para fines relacionados con su vinculación, ejecución y
            terminación de la relación laboral que surja entre el empleado y
            BAVIERA.{" "}
          </p>
          <p className={styles["text-195-0eddde7da79f402aaa34bc34ff994988"]}>
            c) Frente a Proveedores: El tratamiento de los datos se realizará
            con el fin de contactar y contratar con proveedores productos o
            servicios que BAVIERA requiera para el normal funcionamiento de su
            operación y para la adecuada dotación de sus instalaciones u
            oficinas.
          </p>
          <p className={styles["text-196-8788154d5738454484156f43f8660fb0"]}>
            d) Frente a Candidatos de trabajo: El tratamiento de los datos
            personales se realizará con el fin de adelantar procesos de
            selección de personal y contratación de empleados para vincularlos
            laboralmente a la firma.{" "}
          </p>
          <p className={styles["text-577-4de4a47421d04f47b631c2b3b6cd2e56"]}>
            En consecuencia, para las finalidades descritas, BAVIERA podrá: i)
            Conocer, almacenar y procesar toda la información suministrada por
            los titulares en una o varias bases de datos, en el formato que
            estime más conveniente. ii). Ordenar, catalogar, clasificar, dividir
            o separar la información suministrada por los titulares. iii).
            Verificar, corroborar, comprobar, validar, investigar o comparar la
            información suministrada por los titulares, con cualquier
            información de que disponga legítimamente. iv). Acceder, consultar,
            comparar y evaluar toda la información que sobre los titulares se
            encuentre almacenada en las bases de datos de cualquier central de
            riesgo crediticio, financiero, de antecedentes judiciales o de
            seguridad legítimamente constituida, de naturaleza estatal o
            privada, nacional o extranjera. v). Analizar, procesar, evaluar,
            tratar o comparar la 2 información suministrada por los titulares.
            vi). Estudiar, analizar, personalizar y utilizar la información
            suministrada por los titulares para el seguimiento, desarrollo y/o
            mejoramiento, tanto individual como general, de condiciones de
            afiliación, servicio, administración, seguridad o atención, así como
            para el desarrollo de foros académicos, encuentros de promoción
            comercial, almuerzos de afiliados, rondas de negocios y misiones
            comerciales. BAVIERA podrá compartir con sus aliados de negocios que
            se sometan a las condiciones de la presente autorización los
            resultados de los mencionados estudios, análisis, personalizaciones
            y usos, así como toda la información y datos personales
            suministrados por los titulares.{" "}
          </p>
          <p className={styles["text-198-0f6536f2dbda4702bc7b6d52357c8cfd"]}>
            En caso de que BAVIERA no se encuentre en capacidad de realizar el
            tratamiento por sus propios medios, podrá transferir los datos
            recopilados para que sean tratados por un tercero, previa
            notificación a los titulares de los datos recopilados, el cual será
            el encargado del tratamiento y deberá garantizar condiciones idóneas
            de confidencialidad y seguridad de la información transferida para
            el tratamiento
          </p>
          <p className={styles["text-199-0a4a74e0a3694ffd90b910db17b465aa"]}>
            3. Derechos de los Titulares: i) Conocer, actualizar y rectificar
            los datos personales frente a la BAVIERA como responsable o
            encargado del tratamiento, o ejercer el derecho frente a quien haya
            recibido los datos como resultado de la transmisión de los mismos.
            Este derecho se podrá ejercer, entre otros frente a datos parciales,
            inexactos, incompletos, fraccionados, que induzcan a error, o
            aquellos cuyo tratamiento esté expresamente prohibido o no haya sido
            autorizado; ii) Solicitar prueba de la autorización otorgada a la
            BAVIERA como responsable del tratamiento salvo cuando expresamente
            se exceptúe como requisito para el tratamiento; iii) Ser informado
            por la BAVIERA como responsable del tratamiento o el encargado del
            tratamiento, previa solicitud, respecto del uso que le ha dado a mis
            datos personales; iv) Presentar ante la Superintendencia de
            Industria y Comercio quejas por infracciones al régimen de
            protección de datos personales; v) Revocar la autorización y/o
            solicitar la supresión del dato personal cuando en el tratamiento no
            se respeten los principios, derechos y garantías constitucionales y
            legales.; vi) Acceder en forma gratuita los datos personales que
            hayan sido objeto de Tratamiento.
          </p>
          <p className={styles["text-200-33b5037e58134d53b6914075eca4cca7"]}>
            4. Datos Sensibles: Usted tiene derecho a optar por no responder
            cualquier información sensible solicitada por BAVIERA, relacionada
            entre otros, con datos sobre su origen racial o étnico, la
            pertenencia a sindicatos, organizaciones sociales o de derechos
            humanos, convicciones políticas, religiosas, de la vida sexual,
            biométricos o datos de salud.
          </p>
          <p className={styles["text-201-d349dacbcffc41ec8dba4884eebaa02b"]}>
            5. Datos de Menores de Edad: El suministro de los datos personales
            de menores de edad es facultativo y debe realizarse con autorización
            de los padres de familia o representantes legales del menor.
          </p>
          <p className={styles["text-202-cafe4961762a4a82aa0a3aa018347edc"]}>
            6. Atención de Peticiones, Quejas, Consultas y Reclamos: Para
            realizar peticiones, consultas y reclamos con el fin de ejercer sus
            derechos a conocer, actualizar, rectificar, suprimir los datos o
            revocar su autorización por favor contacte a BAVIERA, de Lunes a
            Viernes en horario 8:00 AM – 3 12:00 M y 2:00 PM a 6:00 PM al
            teléfono (+57 1) 7495506 o al correo electrónico
            info@bavieralegal.com.{" "}
          </p>
          <p className={styles["text-203-967bf5a487e04385a124dc38c8679500"]}>
            7. Procedimiento para ejercer los Derechos: Usted tiene derecho a
            solicitar prueba de su autorización otorgada a BAVIERA así como
            conocer, actualizar y rectificar sus datos personales. Para ello
            podrá realizar consultas de Lunes a Viernes en horario 8:00 AM –
            12:00 M y 2:00 PM a 6:00 PM al teléfono 7495506 o al correo
            electrónico info@bavieralegal.com.
          </p>
          <p className={styles["text-204-a7950799264e4088a0dcf321334435e9"]}>
            Al realizar su solicitud de consulta usted deberá presentar los
            siguientes documentos:
          </p>
          <p className={styles["text-205-8e72491f27d04336b4c83c8f9b401065"]}>
            a) Si se trata del Titular: Adjuntar copia del documento de
            identidad.
          </p>
          <p className={styles["text-206-c8d435c1b0884054b174267c969ba8ab"]}>
            b) Si se trata del causahabiente: Documento de identidad, registro
            civil de defunción del Titular, documento que acredite la calidad en
            que actúa y el número del documento de identidad del Titular.
          </p>
          <p className={styles["text-207-10e8043d53c74802b8d08906d69b3b01"]}>
            c) Si se trata de un representante legal y/o apoderado: Documento de
            identidad válido, documento que acredite la calidad en la que actúa
            (Poder) y el número del documento de identidad del Titular.
          </p>
          <p className={styles["text-208-558ef780e20c46eca6e5cf7d86c0fd7b"]}>
            La consulta será atendida en un término máximo de diez (10) días
            hábiles contados a partir de la fecha de radicación. Cuando no fuere
            posible atender la consulta dentro de dicho termino, se le
            informaran los motivos de la demora, y la fecha en que se atenderá
            su consulta, la cual en ningún caso podrá superar los cinco (5) días
            hábiles siguientes al vencimiento del primer término. Usted podrá
            acceder de forma gratuita a sus datos personales y la información
            solicitada podrá ser suministrada por cualquier medio, incluyendo
            los electrónicos, según lo requiera.
          </p>
          <p className={styles["text-209-f3d5701bcb874bedb91e24f1271aea9c"]}>
            Solicitud de supresión de la información de nuestras bases de datos
            o revocatoria de la autorización otorgada para el tratamiento de sus
            datos personales. Para solicitar la supresión de sus datos de
            nuestras bases de datos o revocar la autorización otorgada para el
            tratamiento de sus datos personales usted podrá realizar su
            solicitud de Lunes a Viernes en horario 8:00 AM – 12:00 M y 2:00 PM
            a 6:00 PM al teléfono 7495506 o al correo electrónico
            info@bavieralegal.com
          </p>
          <p className={styles["text-210-a12ae97eb4fb4f9fb19056e9e1ed7e8c"]}>
            Su solicitud debe indicar su intención de que se supriman sus datos
            personales de nuestras bases de datos o de revocar la autorización
            otorgada para el tratamiento de sus datos personales. Así mismo, la
            solicitud debe realizarse identificando claramente el nombre del
            titular, el número de su documento de identidad y sus datos de
            contacto (teléfono y un correo electrónico actualizados).{" "}
          </p>
          <p className={styles["text-578-94ab15f46db04a7fb9a4021104bcf895"]}>
            Al realizar su solicitud de consulta usted deberá presentar los
            siguientes documentos:
          </p>
          <p className={styles["text-212-bdc4822287bf418bb0a1d651a42afb95"]}>
            a) Si se trata del Titular: Adjuntar copia del documento de
            identidad (c.c., t.i., c.e. o pasaporte).{" "}
          </p>
          <p className={styles["text-213-51483cb09dc842838ccf2450efbbd1b1"]}>
            b) Si se trata del causahabiente: Documento de identidad, registro
            civil de defunción del Titular, documento que acredite la calidad en
            que actúa y el número del documento de identidad del Titular.{" "}
          </p>
          <p className={styles["text-214-6821c1d92b164ce094e0e17755e75c60"]}>
            c) Si se trata de un representante legal y/o apoderado: Documento de
            identidad válido, documento que acredite la calidad en la que actúa
            (Poder) y el número del documento de identidad del Titular.{" "}
          </p>
          <p className={styles["text-215-2bec1b7112234c29955c9d1c22ea901a"]}>
            Si la solicitud resulta incompleta, se requerirá al interesado
            dentro de los cinco (5) días siguientes a la recepción de la misma
            para que subsane las fallas. Transcurridos dos (2) meses desde la
            fecha del requerimiento, sin que el solicitante presente la
            información requerida, se entenderá que ha desistido del reclamo. En
            caso de que quien reciba el reclamo no sea competente para
            resolverlo, dará traslado a quien corresponda en un término máximo
            de dos (2) días hábiles e informará de la situación al interesado.
          </p>
          <p className={styles["text-216-f0d970310332438899efbb8961aae082"]}>
            Una vez recibida la solicitud completa, se incluirá en la base de
            datos, en un término no mayor a dos (2) días hábiles, la leyenda
            “solicitud en trámite” y el motivo de la misma. Dicha leyenda se
            mantendrá hasta que la solicitud sea decidida. El término máximo
            para atender la solicitud será de quince (15) días hábiles contados
            a partir del día siguiente a la fecha de su recibo.
          </p>
          <p className={styles["text-217-957fc496c8de4692b0815d22b2c053e2"]}>
            Cuando no fuere posible atender la solicitud dentro de dicho
            término, se informará al interesado los motivos de la demora y la
            fecha en que se atenderá su solicitud, la cual en ningún caso podrá
            superar los ocho (8) días hábiles siguientes al vencimiento del
            primer término.
          </p>
          <p className={styles["text-218-a95a72ef9037498382951c59bc91acde"]}>
            8. Seguridad de la Información: BAVIERA ha adoptado medidas de
            seguridad razonables para proteger la información de los Titulares e
            impedir el acceso no autorizado a sus datos o cualquier
            modificación, divulgación o destrucción no autorizada de los mismos.
            El acceso a los datos personales está restringido a aquellos
            empleados, contratistas, representantes y agentes de BAVIERA
            encargados del tratamiento de los datos y que necesitan conocer los
            mismos para desempeñar sus funciones y desarrollar los servicios de
            la firma. BAVIERA no permite el acceso a esta información por parte
            de terceros en condiciones diferentes a las anunciadas, a excepción
            de un pedido expreso del titular de los datos o personas legitimadas
            de conformidad con la normatividad nacional.
          </p>
          <p className={styles["text-219-81c16cb43e1b460e82758c31bbed26f4"]}>
            No obstante lo anterior, BAVIERA no será responsable por ataques
            informáticos y en general cualquier acción que tenga como objetivo
            infringir las medidas de seguridad establecidas para la protección
            de los datos personales e información diferente a estos contenida en
            sus equipos informáticos o en aquellos contratados con terceros.
          </p>
          <p className={styles["text-220-5dae5f7de7734ca1a8ae48abfc676624"]}>
            9. Fecha de entrada en vigencia de la Política de Tratamiento de la
            Información y periodo de vigencia de las bases de datos de BAVIERA:
            La presente política entra en rigor el día 01 de mayo de 2020 y las
            bases de datos se mantendrán vigentes por todo el tiempo que
            funcione BAVIERA.
          </p>
        </section>
        <section
          className={styles["layer-679-5d55a97b149e458e84838a9563df60a6"]}
        >
          <section
            className={styles["layer-806-bc43b10916504b68b53d4b8dd0aaf785"]}
          >
            <section
              className={styles["layer-196-7f796a355d0a4457abc6e4449049a079"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-465-f653ea73c41e4de7826f01b1ed6ca3f4"]
                  }
                  alt="imageview-465"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-466-fe6be3fddc164a2c8ce89a416d72ccd3"]
                  }
                  alt="imageview-466"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-467-ac67055a20cc42ea8906c08600990e8a"]
                  }
                  alt="imageview-467"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-468-c77cbeec117b48af91f33346f774a31e"]
                  }
                  alt="imageview-468"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-197-0688448011ad480fba09976b0128a105"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-187-f2c4a3b8a3b44410988717f515e9743a"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-188-afd4b51de30744f0813f2662c0a31ef4"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Politicadetratamientodedatos;
