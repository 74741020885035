import React from "react";
import { Link } from "react-router-dom";

import styles from "./mobilemenu.module.css";

/**
 * Mobilemenu component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Mobilemenu component.
 */
const Mobilemenu = () => {
  function aguaRender() {
    return (
      <main className={styles.Mobilemenu}>
        <header className={styles.mobilemenuheader}>
          <Link to="/Home">
            <section
              className={styles["layer-70-877b56a3b9a04fadb5465069d3263fd2"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-202-7184976d1c664e8dabfcbfe6cf10a1f0"]
                  }
                  alt="imageview-202"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-71-88da2b854d2f4e57bac0120f564dab4e"]}
          >
            <Link to="/Home">
              <span
                className={styles["icon-3-7bd413645027488dbbc8ee6093395b0f"]}
              >
                keyboard_arrow_up
              </span>
            </Link>
          </section>
        </header>
        <section className={styles.mobilemenusection1}>
          <section className={styles.mobilemenusections}>
            <section
              className={styles["layer-72-3f049c0123b541d8a4f7e310332cfd98"]}
            >
              <Link to="/Nosotros">
                <p
                  className={styles["text-59-88b328d2847c43d6a918ee18b36030cc"]}
                >
                  Nosotros
                </p>
              </Link>
              <Link to="/Equipo">
                <p
                  className={styles["text-60-19f7f8b675284670b6c30d2a8d2fcf41"]}
                >
                  Equipo
                </p>
              </Link>
              <Link to="/Areasdepractica">
                <p
                  className={styles["text-61-1f11c897ae804526a9f6554f1dcfbaf3"]}
                >
                  Áreas de Práctica
                </p>
              </Link>
              <Link to="/Contacto">
                <p
                  className={styles["text-62-2ed4b81989d941688f6a5dbc7dc0f8bc"]}
                >
                  Contacto
                </p>
              </Link>
            </section>
            <section
              className={styles["layer-74-49f97056fd894b4d84460b167ae61355"]}
            >
              <Link to="/Agendatucapactitacion">
                <p
                  className={styles["text-63-26ef4549af7748be9fd7ac1e017f384e"]}
                >
                  Agenda tu Capacitación
                </p>
              </Link>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Mobilemenu;
