import React from "react";
import { Link } from "react-router-dom";
import IframeComponent from "../../components/iframeComponent/iframeComponent.js";

import styles from "./home.module.css";

/**
 * Home component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Home component.
 */
const Home = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Home}>
        <header className={styles.homeheader}>
          <Link to="/Home">
            <section
              className={styles["layer-2-3d08f9ba4b6d44e38b52a6eea671db40"]}
            >
              <picture>
                <img
                  className={styles.bavieralogo}
                  alt="bavieralogo"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-3-5e6ce39d6d51493a8669ba30f486bc31"]}
          >
            <Link to="/Nosotros">
              <p className={styles["text-2-61d7d9db557943988ee7cbf6c7a87f19"]}>
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p className={styles["text-3-5a304adcd3564e858c39be3eddb21239"]}>
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p className={styles["text-4-8fcc6e377d604e30a70030835dc3dfa1"]}>
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p className={styles["text-5-af6cbc7d5d3d4c83b1261c529fc185b7"]}>
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-4-753cd528892f4768a0ac5e207115f7a6"]}
          >
            <section
              className={styles["layer-6-e5e47c53dbc444899e1ace36ef78a685"]}
            >
              <Link to="/Agendatucapactitacion">
                <p
                  className={styles["text-1-55678b833689471cab623acf2f26ea0d"]}
                >
                  Agenda tu Capacitación
                </p>
              </Link>
            </section>
          </section>
          <section
            className={styles["layer-5-11f9ece7ab4b46a88674e64e8c7a7ce2"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-1-c7c1bcc9531d4248a041d32a759fcd23"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </header>
        <section className={styles.homesection1}>
          <section
            className={styles["layer-461-2e55c1429e5b47f39764cbab51cf03d5"]}
          >
            <p className={styles["text-73-be9b44557e13492faadcc957037911c3"]}>
              ALIADOS LEGALES PARA TU NEGOCIO.
            </p>
            <p className={styles["text-74-6eec02498f594789be456d4430358849"]}>
              Una firma especializada en derecho de los negocios, con reconocida
              experiencia en derecho laboral y seguridad social, derecho
              migratorio y derecho tributario.
            </p>
            <p className={styles["text-75-35e2d7494ef24839ba839307d19d9b4a"]}>
              SOMOS ESTRATÉGICOS E INNOVADORES.
            </p>
            <section
              className={styles["layer-88-2d54f9ce2a4141bcb83086f6a796c529"]}
            >
              <Link to="/Contacto">
                <p
                  className={styles["text-76-dfb9d1c386e04375b44eb1f148694a46"]}
                >
                  Contáctanos
                </p>
              </Link>
            </section>
          </section>
        </section>
        <section className={styles.homesection2}>
          <section
            className={styles["layer-460-41f4ea6174ea4e2cbdf539db1c80ff23"]}
          >
            <p className={styles["text-412-7d170979998248e29e5d343ef7fa5da3"]}>
              NUESTRAS ÁREAS DE PRÁCTICA
            </p>
          </section>
          <section
            className={`${styles["layer-10-b5b2031d9d95455da1ee29c59104c389-orientation"]} ${styles["layer-10-b5b2031d9d95455da1ee29c59104c389"]}`}
          >
            <section
              className={styles["layer-12-617745cc5f5a439fafbe093b5d77b90b"]}
            >
              <Link to="/Derechodelosnegocios">
                <section
                  className={
                    styles["layer-547-9f0d99d76dbe4ad880577e7253117e71"]
                  }
                >
                  <img
                    className={
                      styles["imageview-198-09246f266ec04f5d94553b60f47a0e9c"]
                    }
                    alt="imageview-198"
                    src="/assets/Derecho de los negocios_square.png"
                  />
                </section>
              </Link>
              <Link to="/Derechodelosnegocios">
                <p
                  className={styles["text-7-a7ac179f78fc4b6194c754482f8ba3a0"]}
                >
                  Derecho de los Negocios
                </p>
              </Link>
            </section>
            <section
              className={styles["layer-13-375e0f56ba91470dbf8498e41aa04564"]}
            >
              <Link to="/Derecholaboral">
                <section
                  className={
                    styles["layer-548-56e92fe039794446a2535372370edabf"]
                  }
                >
                  <img
                    className={
                      styles["imageview-199-8235e661781e47aab37b57a78772b172"]
                    }
                    alt="imageview-199"
                    src="/assets/Derecho laboral_square.png"
                  />
                </section>
              </Link>
              <Link to="/Derecholaboral">
                <p
                  className={styles["text-8-e17bf5425694494a919c8a9185b3b203"]}
                >
                  Derecho Laboral y Seguridad Social
                </p>
              </Link>
            </section>
            <section
              className={styles["layer-14-677393b17ba0472a9774252111d7e1ae"]}
            >
              <Link to="/Derechomigratorio">
                <section
                  className={
                    styles["layer-549-9fe93dd87a814651aaec4a8f866ddacc"]
                  }
                >
                  <img
                    className={
                      styles["imageview-200-943d490e929e49d18002877202cd039e"]
                    }
                    alt="imageview-200"
                    src="/assets/Derecho migratorio_square.png"
                  />
                </section>
              </Link>
              <Link to="/Derechomigratorio">
                <p
                  className={styles["text-9-f7e726d5d02545c2821fd206d8dd11af"]}
                >
                  Derecho Migratorio
                </p>
              </Link>
            </section>
            <section
              className={styles["layer-15-d9bb8bd34c7d466daa9000ffa20449ee"]}
            >
              <Link to="/Seguridadysalud">
                <section
                  className={
                    styles["layer-550-736b9152a4db43708377401c5b4611a9"]
                  }
                >
                  <img
                    className={
                      styles["imageview-201-1afee32b0e7d4cadbf0207185760f4f7"]
                    }
                    alt="imageview-201"
                    src="/assets/Seguridad y Salud en el Trabajo_square.png"
                  />
                </section>
              </Link>
              <Link to="/Seguridadysalud">
                <p
                  className={styles["text-10-b5f74ed610464260a65c68fc529c7d7d"]}
                >
                  Seguridad y Salud en el Trabajo
                </p>
              </Link>
            </section>
            <section
              className={styles["layer-16-02c9afd51dc940ac8cc71d1ecda2c342"]}
            >
              <Link to="/Derechotributario">
                <section
                  className={
                    styles["layer-551-f9e26648344b491d8b41aca519c1fc30"]
                  }
                >
                  <img
                    className={
                      styles["imageview-202-8029b3f9f7f5493aa13a9028379a3d48"]
                    }
                    alt="imageview-202"
                    src="/assets/Derecho Tributario_square.png"
                  />
                </section>
              </Link>
              <Link to="/Derechotributario">
                <p
                  className={styles["text-11-54678da62af048c9a48c289ce046d471"]}
                >
                  Derecho Tributario
                </p>
              </Link>
            </section>
          </section>
        </section>
        <section
          className={`${styles["homesection3-orientation"]} ${styles["homesection3"]}`}
        >
          <section
            className={styles["layer-463-fd5f7689f3de4b8caf6cc004c7ac10e5"]}
          >
            <p className={styles["text-413-8907e6bd3afd4633892247b99a4114ad"]}>
              ALGÚNOS DE NUESTROS CLIENTES
            </p>
          </section>
          <section
            className={`${styles["layer-462-005da6786f074e03b60ff6ce70a6093e-orientation"]} ${styles["layer-462-005da6786f074e03b60ff6ce70a6093e"]}`}
          >
            <section
              className={styles["layer-47-b340f6e15b6243878f7770afc59489b3"]}
            >
              <section
                className={styles["layer-905-a11a01210c7e47aaa9aa9383c96bbbc5"]}
              >
                <img
                  className={
                    styles["imageview-284-7ec07b741a8d490eaaebe57ab0cc478e"]
                  }
                  alt="imageview-284"
                  src="/assets/vlex - Square.png"
                />
              </section>
              <p className={styles["text-30-d2ca6f1e7c294abf98da5c5e4d58ce5d"]}>
                VLEX
              </p>
            </section>
            <section
              className={styles["layer-51-79e347ccf3104a498b25e704559487e3"]}
            >
              <section
                className={styles["layer-909-971eb83f20734f30b38a476b1dab4610"]}
              >
                <img
                  className={
                    styles["imageview-288-999a499f799b4216abc09ec1bc8bcfa1"]
                  }
                  alt="imageview-288"
                  src="/assets/Ipcom - Square.png"
                />
              </section>
              <p className={styles["text-33-22a2d02845bb4d0897a61ba5d7bbf191"]}>
                IPCOM
              </p>
            </section>
            <section
              className={styles["layer-49-eed115637b6d48c894db29338fc01e4d"]}
            >
              <section
                className={styles["layer-907-3f6aea514aa2478d96c146c5528a006d"]}
              >
                <img
                  className={
                    styles["imageview-286-36f8e54109804ee297de89c828cf29ac"]
                  }
                  alt="imageview-286"
                  src="/assets/Exicarton - Square.png"
                />
              </section>
              <p className={styles["text-31-4ef663560d984ff2b64b9441063997be"]}>
                EXICARTON
              </p>
            </section>
            <section
              className={styles["layer-911-0c007086943c4da381594c0dd9140c1d"]}
            >
              <section
                className={styles["layer-915-7de783995fe5403ebc5190e896b84761"]}
              >
                <img
                  className={
                    styles["imageview-290-636a2808d7fb4118a2721946df42a87c"]
                  }
                  alt="imageview-290"
                  src="/assets/ImproMarkas - Square.png"
                />
              </section>
              <p
                className={styles["text-737-4a23114c1d434b88badd0d96318ced17"]}
              >
                IMPROMARKAS
              </p>
            </section>
            <section
              className={styles["layer-910-09075171494d4fe8b95fde1bd80e72a6"]}
            >
              <section
                className={styles["layer-914-d545e576c9ff4b73bf29e271c41b830d"]}
              >
                <img
                  className={
                    styles["imageview-289-34d8b82f1bbb4bca976c3af4d5bcc069"]
                  }
                  alt="imageview-289"
                  src="/assets/Intelsa - Square.png"
                />
              </section>
              <p
                className={styles["text-736-559bcbe524704bf0b39b1b6ca64ed92f"]}
              >
                INTELSA
              </p>
            </section>
            <section
              className={styles["layer-50-c8596553f8084f7c8d7c33984f3eb2ca"]}
            >
              <section
                className={styles["layer-908-4c9e25658e69423c89d6a2f97dd251ee"]}
              >
                <img
                  className={
                    styles["imageview-287-8bda81d1be7e47aead79e20fee3003c0"]
                  }
                  alt="imageview-287"
                  src="/assets/Aprende - Square.png"
                />
              </section>
              <p className={styles["text-32-c0ff9bd68fda4420ac2b649434213f28"]}>
                APRENDE INSTITUTE
              </p>
            </section>
            <section
              className={styles["layer-529-c35806fb7cc344e6acee5becb851237b"]}
            >
              <section
                className={styles["layer-533-c77b6d3ee4c940b096b27242f7581cd9"]}
              >
                <img
                  className={
                    styles["imageview-172-4c356a1dfb594e21b4553de568e3b6ff"]
                  }
                  alt="imageview-172"
                  src="/assets/Nagarro - Square.png"
                />
              </section>
              <p
                className={styles["text-420-bce4d0ccbcdd4202ae7488794c57fcfe"]}
              >
                NAGARRO
              </p>
            </section>
            <section
              className={styles["layer-913-99ba4ff0683c459ab446e25e3a31329d"]}
            >
              <section
                className={styles["layer-917-3c59f69d8ce4473090fa10f4ca006e6a"]}
              >
                <img
                  className={
                    styles["imageview-292-3c0de2a151d546608a30bc899edb6cb4"]
                  }
                  alt="imageview-292"
                  src="/assets/HMF - Square.png"
                />
              </section>
              <p
                className={styles["text-739-0bd843d194c74a15981f438e1f05e10e"]}
              >
                HMF INGENIERÍA
              </p>
            </section>
            <section
              className={styles["layer-48-3249c65d8d004ca4bbf6b5b42410ed38"]}
            >
              <section
                className={styles["layer-906-14e159096b18422f8980f85e41a20caf"]}
              >
                <img
                  className={
                    styles["imageview-285-13c68cddd5f54222a46dc5564a563603"]
                  }
                  alt="imageview-285"
                  src="/assets/CallZilla - Square.png"
                />
              </section>
              <p className={styles["text-29-99e8482831ae4fe0876b5208d61d2e80"]}>
                CALLZILLA
              </p>
            </section>
            <section
              className={styles["layer-912-5a38091f55d24642a9331d332b154b07"]}
            >
              <section
                className={styles["layer-916-c6527fd139834091a97927affb593888"]}
              >
                <img
                  className={
                    styles["imageview-291-a09853d6e4ad448b95b7b83b226ed3c7"]
                  }
                  alt="imageview-291"
                  src="/assets/Motus - Square.png"
                />
              </section>
              <p
                className={styles["text-738-a4a73fe769cc475eb064a7273c3c5b36"]}
              >
                MOTUS
              </p>
            </section>
            <section
              className={styles["layer-530-22a115fd7b4747e6a94b58701a808a80"]}
            >
              <section
                className={styles["layer-532-6a6c0aa65da54989aa6b65cda366f7d1"]}
              >
                <img
                  className={
                    styles["imageview-173-62b0a8e116dc4a42a52fd21a9061023c"]
                  }
                  alt="imageview-173"
                  src="/assets/BentoInt - Square.png"
                />
              </section>
              <p
                className={styles["text-421-da9bd6f8f03a4932940baf60b8f3b3af"]}
              >
                BENTOINT
              </p>
            </section>
          </section>
        </section>
        <section
          className={`${styles["homesection4-orientation"]} ${styles["homesection4"]}`}
        >
          <section
            className={styles["layer-465-0e48bb8d134647028876f5c8cde732eb"]}
          >
            <p className={styles["text-414-d4172df906634e6d986018dbab71f624"]}>
              LA EXPERIENCIA BAVIERA
            </p>
          </section>
          <section
            className={`${styles["layer-500-70b50d7b57e44903ba3f11d67d038022-orientation"]} ${styles["layer-500-70b50d7b57e44903ba3f11d67d038022"]}`}
          >
            <section
              className={styles["layer-572-4d457ed652c44e91a41ef6198816c00f"]}
            >
              <section
                className={styles["layer-464-afa2a5cde3864fe88ba599abc6067850"]}
              >
                <img
                  className={
                    styles["imageview-164-3854a3065ac34686863a563a3d3741ff"]
                  }
                  alt="imageview-164"
                  src="/assets/CallZilla - Square.png"
                />
              </section>
              <section
                className={styles["layer-573-f2a163794c2f424a89a2e31635df2100"]}
              >
                <p
                  className={styles["text-34-d1c8c28dc3dd4ee48b3102715f049ab4"]}
                >
                  Natalia Diaz
                </p>
                <p
                  className={styles["text-35-ac987fe3aedf4e0a82603430b1d2ea48"]}
                >
                  Gerente General
                </p>
                <p
                  className={styles["text-38-62dbffd0adbc4123ae7d512d71e34ca3"]}
                >
                  “Durante el tiempo que hemos trabajado con Baviera hemos
                  comprobado su profesionalismo, conocimiento legal solido en el
                  sector y un compromiso muy alto con el éxito de nuestras
                  consultas. Su enfoque personalizado y atención a los detalles
                  nos hacen tener mucha confianza en ellos.”
                </p>
              </section>
            </section>
            <section
              className={styles["layer-54-0978b6d59d64488db55e7c5c8215035a"]}
            >
              <section
                className={styles["layer-545-13de03b1d2f94b559674539a1bca3202"]}
              >
                <img
                  className={
                    styles["imageview-196-2a970db8d9c44706806f2711bc2fbb63"]
                  }
                  alt="imageview-196"
                  src="/assets/Nagarro - Square.png"
                />
              </section>
              <p className={styles["text-40-8c2b570dedda4e39bfe5a990ff9f5b94"]}>
                Angelica Pinzón
              </p>
              <p className={styles["text-41-340af16347db4b00b99909e19981ace7"]}>
                Colombia Human Resources Lead
              </p>
              <p className={styles["text-42-a6e3d9fa016f4a81b8ca897e4a7f34b2"]}>
                “Para Nagarro, el acompañamiento de Baviera ha sido fundamental
                en la apertura de nuestras operaciones en Colombia, gracias a su
                oportuna y excelente asesoría hemos logrado avanzar en nuestra
                estructura y establecimiento de políticas y procedimientos en
                compliance con la legislación local”
              </p>
            </section>
            <section
              className={styles["layer-53-0508c285e0364557aa843a3003f85a12"]}
            >
              <section
                className={styles["layer-546-070167c8d5ef4c6d91542a7e0dcadfe2"]}
              >
                <img
                  className={
                    styles["imageview-197-6606eeeb479d45b1b0047eee77339248"]
                  }
                  alt="imageview-197"
                  src="/assets/BentoInt - Square.png"
                />
              </section>
              <p className={styles["text-36-894be80911dd4819ad1ba7f50c98f8fc"]}>
                Tania Mariaca
              </p>
              <p className={styles["text-37-5c162c7952b14e509f8bff8ed5f2283b"]}>
                {" "}
                Directora de Administración y Finanzas
              </p>
              <p className={styles["text-39-1de9c3c58cf6404c82353a08d7125c29"]}>
                “Baviera Legal uno de nuestros mejores aliados, es un despacho
                totalmente profesional, brinda un servicio a la medida de cada
                uno de los clientes. Altamente recomendable!!!!”
              </p>
            </section>
          </section>
        </section>
        <section className={styles.homesection5}>
          <section
            className={styles["layer-464-e44e86c9a03a44c598b7967f7fb0ee8c"]}
          >
            <p className={styles["text-412-f65b8e716df84f809599a24c660e98f4"]}>
              ACTUALIDAD
            </p>
          </section>
          <section
            className={styles["layer-3108-feca6ee1f9534104ac857129a21a7d1f"]}
          >
            <IframeComponent
              width={"100%"}
              height={"100%"}
              sourceURL={"https://www.instagram.com/bavieralegal/embed/"}
            />
          </section>
        </section>
        <footer className={styles.homefooter}>
          <section
            className={styles["layer-465-ae4a5b0dbc9e4006bc0572d4154f66c7"]}
          >
            <section
              className={styles["layer-481-41ee69d9b1374da9ae51cd716a8e6dfe"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-164-00d2003f8801484abf7064d16b4c4f1b"]
                  }
                  alt="imageview-164"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-165-47bd3700c7a3465e9acfd5860ff7c506"]
                  }
                  alt="imageview-165"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-166-ed46fa2d855b4bc3bf6aace80db974a2"]
                  }
                  alt="imageview-166"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-167-d37a205ae13644a7970ad3d8d26561a7"]
                  }
                  alt="imageview-167"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-467-df3802d767a345d4bca59e0b3f7f49ca"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={styles["text-43-3b6d765ee7624927a76a7302e1e10daa"]}
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-106-81287af6f601496ab2ed4c44b05488d6"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </footer>
      </main>
    );
  }

  return aguaRender();
};

export default Home;
