import React from "react";
import { Link } from "react-router-dom";

import styles from "./areasdepractica.module.css";

/**
 * Areasdepractica component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Areasdepractica component.
 */
const Areasdepractica = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Areasdepractica}>
        <section className={styles.areasdepracticaheader}>
          <Link to="/Home">
            <section
              className={styles["layer-112-6c6ae025310f45bca25605d15fc721c0"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-252-bd4d1eb68f394ea2b338cad83c217efb"]
                  }
                  alt="imageview-252"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-113-655def239ea44152bc9b532895aa9e3a"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-104-5ccb1d7c3a2a4a8da3e39699076d5f0d"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-105-673cd3e949ff4134bebb5a71880a8684"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-106-8c35cfc506594f68b041127f6994cefa"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-107-6133556da82e45aba737202df37f1931"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-114-5395ab6e28cc4d51b4a0c716a2e94fac"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-103-c5c0c21aa2e642598e29af1408429d70"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-115-fbf4592cb836416c969f70f5e2a0e8ee"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-5-fc135017ad7b4694bc615f4849a45d69"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section
          className={`${styles["areasdepracticasections-orientation"]} ${styles["areasdepracticasections"]}`}
        >
          <section className={styles.areasdepracticasection1}>
            <section
              className={styles["layer-473-30c9bdeffbfc4c9586b9384a1b7ba9ee"]}
            >
              <p
                className={styles["text-424-46f53917ad464e179def4d6e387f832c"]}
              >
                ÁREAS DE PRÁCTICA
              </p>
            </section>
          </section>
          <section className={styles.areasdepracticasection2}>
            <section
              className={styles["layer-105-9437ab65e5b2417da06b14da6a7c330d"]}
            >
              <section
                className={styles["layer-724-45be52233c4a41bd9cfb70c10b840053"]}
              >
                <Link to="/Derechodelosnegocios">
                  <p
                    className={
                      styles["text-98-81c38722a0724aaf96553a4d4b170cd8"]
                    }
                  >
                    Derecho de los Negocios
                  </p>
                </Link>
              </section>
              <section
                className={styles["layer-723-fe4a85cb47c64fca87f5fb80713a3364"]}
              >
                <Link to="/Derechodelosnegocios">
                  <p
                    className={
                      styles["text-588-50293e9869904db38922ea0113daffb6"]
                    }
                  >
                    Constitución y liquidación de sociedades nacionales,
                    reformas estatutarias y acompañamiento legal en procesos de
                    adquisiciones...
                  </p>
                </Link>
              </section>
            </section>
            <section
              className={styles["layer-106-8794ad9f64e04b098e64681c5bb4a167"]}
            >
              <section
                className={styles["layer-725-18e2a778bbb745baa9be7ac72b0b33a7"]}
              >
                <Link to="/Derecholaboral">
                  <p
                    className={
                      styles["text-99-4d7261a5daa04d488fa7b4109e58e3ab"]
                    }
                  >
                    Derecho Laboral y Seguridad Social
                  </p>
                </Link>
              </section>
              <section
                className={
                  styles["layer-3108-d173fe693bd54c2abc7a793a88ebb6ff"]
                }
              >
                <Link to="/Derecholaboral">
                  <p
                    className={
                      styles["text-589-edc9d5be10bd454e9cab2db0b9cea520"]
                    }
                  >
                    Vinculación de personal, redacción de modelos de contratos
                    de trabajo para diferentes cargos...
                  </p>
                </Link>
              </section>
              <section
                className={styles["layer-726-7eb28cd100a64ba3995eb684d625dd79"]}
              ></section>
            </section>
            <section
              className={styles["layer-107-2ae52e136d8a4b148e1e56d502b900bc"]}
            >
              <section
                className={styles["layer-727-a67364d52cb74ab8a6f066ebd871ae9e"]}
              >
                <Link to="/Derechomigratorio">
                  <p
                    className={
                      styles["text-100-4db6eb61e75a4ffe8c14a921c0a761ab"]
                    }
                  >
                    Derecho Migratorio
                  </p>
                </Link>
              </section>
              <section
                className={styles["layer-730-a9053730d74740448e6f77b7e7e4127a"]}
              >
                <Link to="/Derechomigratorio">
                  <p
                    className={
                      styles["text-590-ca98d0b8646942f9becce8e2e20237c6"]
                    }
                  >
                    Trámite de visas y cédulas de extranjería, redacción de
                    modelos de contratos de trabajo para diferentes cargos...
                  </p>
                </Link>
              </section>
            </section>
            <section
              className={styles["layer-108-6634e7881dc344f7aa72ef86ddfc50d7"]}
            >
              <section
                className={styles["layer-728-74c7e4a778af4e1fb60e29b44c9b29ce"]}
              >
                <Link to="/Seguridadysalud">
                  <p
                    className={
                      styles["text-101-fd769ce6d15b47b2b6b787d94546bee9"]
                    }
                  >
                    Seguridad y Salud en el Trabajo
                  </p>
                </Link>
              </section>
              <section
                className={styles["layer-731-a0a914708d1e4cc3a3aa645a32c88c0d"]}
              >
                <Link to="/Seguridadysalud">
                  <p
                    className={
                      styles["text-591-677f153832e5499597fed6553a66edae"]
                    }
                  >
                    Comité paritario de salud y seguridad en el trabajo –
                    COPASST, cumplimiento de obligaciones regulatorias...
                  </p>
                </Link>
              </section>
            </section>
            <section
              className={styles["layer-109-2de9a513daab46879388010ff5c8c7a5"]}
            >
              <section
                className={styles["layer-729-8762de753ddf403e8c4d14b7c5de4c31"]}
              >
                <Link to="/Derechotributario">
                  <p
                    className={
                      styles["text-102-4b00d730ab274051908b8e2ab60e32ea"]
                    }
                  >
                    Derecho Tributario
                  </p>
                </Link>
              </section>
              <section
                className={styles["layer-732-7dfabd7616db4f96a37980c03b4cc549"]}
              >
                <Link to="/Derechotributario">
                  <p
                    className={
                      styles["text-592-5c332a7e1073408d8c57abffd6c152d6"]
                    }
                  >
                    Tributación nacional e internacional de personas naturales y
                    jurídicas, tributación en procesos de adquisiciones,
                    fusiones y escisiones de empresas.
                  </p>
                </Link>
              </section>
            </section>
          </section>
        </section>
        <section
          className={styles["layer-525-0432ec8d04e24927926df9c414d68f44"]}
        >
          <section
            className={styles["layer-554-b7c8c85d39bd4d8e8f4d34d997e8aab8"]}
          >
            <section
              className={styles["layer-1043-265b3e58eb6c4ff6a476e53af9a6bf98"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-296-9a7b5deb8dd043abad1b86a55372fd74"]
                  }
                  alt="imageview-296"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-297-09ea20d1d14d4ef19573a11f50df786e"]
                  }
                  alt="imageview-297"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-298-452738ffa50e45e183a79e600eb0bb26"]
                  }
                  alt="imageview-298"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-299-b41a89827bca4fb0b15d98988090f4fb"]
                  }
                  alt="imageview-299"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-111-ab0b6e55cfbf49a0afb5d9c0b0bb4411"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={styles["text-96-04e9b3a7f5c549269a7250bb65c21a4f"]}
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p className={styles["text-97-25890b7d195f4122927181352ccb4e81"]}>
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Areasdepractica;
