import React from "react";
import { Link } from "react-router-dom";

import styles from "./seguridadysalud.module.css";

/**
 * Seguridadysalud component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Seguridadysalud component.
 */
const Seguridadysalud = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Seguridadysalud}>
        <section className={styles.seguridadysaludheader}>
          <Link to="/Home">
            <section
              className={styles["layer-648-a89a154cc8ca4736b42da2b8af508242"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-371-ecb22591fcbb42ffb904dc8083e2fc8f"]
                  }
                  alt="imageview-371"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-649-feaef8f5d1184c1d93e556864db82e5a"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-551-3c0b77885128471bbcb40ed8ef4af0ee"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-552-333536eddf814e619193bd31a74500ca"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-553-01c143ee9f5e4dd49214da842c0f4a72"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-554-586d02b3c88045e1b48f1d5b71dd2b12"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-650-1fc2f18bfab54595a6d1c220072f682f"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-555-5142e1adfa1c4279a039b9fcec3c1526"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-651-879177f25ca644939ce8c61e36083f99"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-23-31ec0fdeb6784b11a1f5baeeac75634f"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section className={styles.seguridadysaludsection1}>
          <p className={styles["text-549-a6c003f713774254af099102d6b130ae"]}>
            SEGURIDAD Y SALUD EN EL TRABAJO
          </p>
        </section>
        <section
          className={`${styles["seguridadysaludsection2-orientation"]} ${styles["seguridadysaludsection2"]}`}
        >
          <section
            className={styles["layer-366-7a1fd54a29564db6857595ba516dece5"]}
          >
            <section
              className={`${styles["layer-755-14a6bf7dd0b14db7a52cecdb935e1ac9-orientation"]} ${styles["layer-755-14a6bf7dd0b14db7a52cecdb935e1ac9"]}`}
            >
              <section
                className={styles["layer-677-e83f0dd71ed34a2a8e93abac67d38cc0"]}
              >
                <section
                  className={
                    styles["layer-682-92420c29d7eb48fe8565a7b7c29a395a"]
                  }
                >
                  <p
                    className={
                      styles["text-557-7c0177875ec4454aa6693277e24a070c"]
                    }
                  >
                    1.
                  </p>
                </section>
                <section
                  className={
                    styles["layer-681-69586c71e8a24b8084710b2813b6e2b7"]
                  }
                >
                  <p
                    className={
                      styles["text-556-beca734d743d48aab5d12dba4ad1abde"]
                    }
                  >
                    Comité paritario de salud y seguridad en el trabajo –
                    COPASST.
                  </p>
                </section>
              </section>
              <section
                className={styles["layer-678-b3801fddd61e44019e27dc47f0c6b800"]}
              >
                <section
                  className={
                    styles["layer-683-685bf3f5451e40b4bea2efcdf6ce0572"]
                  }
                >
                  <p
                    className={
                      styles["text-555-e0094f4ff34a43f2af02fb251947f03b"]
                    }
                  >
                    2.
                  </p>
                </section>
                <section
                  className={
                    styles["layer-684-57aa3cab63e94a2c9a16e8b1036808ef"]
                  }
                >
                  <p
                    className={
                      styles["text-554-a892731dc51c4c0d88bdb3b699809f93"]
                    }
                  >
                    Cumplimiento de obligaciones regulatorias.
                  </p>
                </section>
              </section>
              <section
                className={styles["layer-679-204f5355a47247d1b8ff58e0010e98a0"]}
              >
                <section
                  className={
                    styles["layer-685-5e8925d9913d404480fd7f4ef0af64d4"]
                  }
                >
                  <p
                    className={
                      styles["text-553-d185ff09fabd420f88ff8fc2f76a7bbf"]
                    }
                  >
                    3.
                  </p>
                </section>
                <section
                  className={
                    styles["layer-686-1aa1a3f07632479183ca834bc03c5ecd"]
                  }
                >
                  <p
                    className={
                      styles["text-552-92c43fb035b746fb94fbea922098b02d"]
                    }
                  >
                    Sistema de Gestión en Seguridad y Salud en el Trabajo –
                    SGSST.
                  </p>
                </section>
              </section>
            </section>
            <section
              className={`${styles["layer-756-90a7ae03186e422b9a373018255acec6-orientation"]} ${styles["layer-756-90a7ae03186e422b9a373018255acec6"]}`}
            >
              <section
                className={styles["layer-680-31b7a027473545f7b13decf439489628"]}
              >
                <section
                  className={
                    styles["layer-687-ffc6387f32bc4db69d24f6c874afba11"]
                  }
                >
                  <p
                    className={
                      styles["text-551-f06b936a42254449a65c1ac3289edf7f"]
                    }
                  >
                    4.
                  </p>
                </section>
                <section
                  className={
                    styles["layer-688-7a3d6f22c49a49a0a561f762ea3ec1a5"]
                  }
                >
                  <p
                    className={
                      styles["text-550-d5a87fd96a85422ca905844b93846e38"]
                    }
                  >
                    Capacitaciones.
                  </p>
                </section>
              </section>
              <section
                className={styles["layer-689-54475ac3c88446ab89edb87d3ceb7e6a"]}
              >
                <section
                  className={
                    styles["layer-692-b18ace6a147a43439f482bec847af92c"]
                  }
                >
                  <p
                    className={
                      styles["text-563-40a8c43e4bc2405ba7116ea239ca0a8f"]
                    }
                  >
                    5.
                  </p>
                </section>
                <section
                  className={
                    styles["layer-693-ce1e18bb48064c078d7d9fff95349cec"]
                  }
                >
                  <p
                    className={
                      styles["text-562-cb0535a830db4aa3b7b863fea1e9142e"]
                    }
                  >
                    Auditorías de cumplimiento.
                  </p>
                </section>
              </section>
              <section
                className={styles["layer-690-a5a987ab712d49fa91d5e817d57f1652"]}
              >
                <section
                  className={
                    styles["layer-694-bc85b49450904f3b8b5e2e1c096dc464"]
                  }
                >
                  <p
                    className={
                      styles["text-561-679062f3a29a47aba036df91c3bf5596"]
                    }
                  >
                    6.
                  </p>
                </section>
                <section
                  className={
                    styles["layer-695-98e60de1f80d46c4bd520ecf8a8a958b"]
                  }
                >
                  <p
                    className={
                      styles["text-560-b8851a30fc234525b0a450c9eeeedb66"]
                    }
                  >
                    Plan anual.
                  </p>
                </section>
              </section>
            </section>
            <section
              className={`${styles["layer-757-53bed54837e343aba9d269dd1802dd19-orientation"]} ${styles["layer-757-53bed54837e343aba9d269dd1802dd19"]}`}
            >
              <section
                className={styles["layer-691-3c13aa8593ac4db4a414f80438f158fa"]}
              >
                <section
                  className={
                    styles["layer-696-0f85d8eea48b4a15adff54cb428e200e"]
                  }
                >
                  <p
                    className={
                      styles["text-558-a2e0672e09534d9e86700252a21822ee"]
                    }
                  >
                    7.
                  </p>
                </section>
                <section
                  className={
                    styles["layer-697-c0469288876942d49bf7d5c69178ad37"]
                  }
                >
                  <p
                    className={
                      styles["text-559-74e0e671fe0e45ffb33eac7238096e9f"]
                    }
                  >
                    Matrices, profesiogramas, entre otros.
                  </p>
                </section>
              </section>
            </section>
          </section>
        </section>
        <section
          className={styles["layer-857-d74227c239e6450490d222b3070b6baa"]}
        >
          <section
            className={styles["layer-984-ec89b7891dc748eaa0229edac40ccaf0"]}
          >
            <section
              className={styles["layer-677-9696947ef7564802ba11b37302674a7e"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-312-7b5bb868fd344543ad163c15ef46f951"]
                  }
                  alt="imageview-312"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-313-aa62bce87f4a4ca69a084cf66998f40b"]
                  }
                  alt="imageview-313"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-314-f82c4eb2eddb4b269d9dd4f2db1ce46d"]
                  }
                  alt="imageview-314"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-315-318f403a5b424e4ca8646f4e38ccd1a9"]
                  }
                  alt="imageview-315"
                  src="/assets/llamada-telefonica.png"
                />
              </picture>
            </section>
            <section
              className={styles["layer-678-a60467f42fae482fb142bb11ba42c6c5"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-639-ec6b0dc92e5b476fbe2c79db575c64e9"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-640-405a9fa1baa7424ea6a33abdd949c465"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Seguridadysalud;
