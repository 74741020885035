import React from "react";
import { Link } from "react-router-dom";

import styles from "./derechomigratorio.module.css";

/**
 * Derechomigratorio component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Derechomigratorio component.
 */
const Derechomigratorio = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Derechomigratorio}>
        <section className={styles.derechomigratorioheader}>
          <Link to="/Home">
            <section
              className={styles["layer-550-a643fc926e194856b2708bff5c77b884"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-365-40bddfe92ca54c259a94a71ba1cf68ce"]
                  }
                  alt="imageview-365"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-551-52961a92d33f4fecb02a129be64e38cd"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-491-8149e67896ae48059b8490497d0d99c6"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-492-3d8cec224528416f8351fb5b5e0dcb4c"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-493-9f4f92869d114263a56723f2cf79c5bb"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-494-901bae9a95934e3382591272d6b3980c"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-552-b0505bd0ad3441a0b5fa9f5820f072a2"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-495-586af7d7511e4e0db72fc0467e54f54a"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-553-b4e0efbdb6fc41aa92290d58b9a257bf"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-20-29cb79f09bf84d1c8f6391908e7b5c65"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section className={styles.derechomigratoriosection1}>
          <p className={styles["text-531-ea488e30006e4bf085edd96529a67bd4"]}>
            DERECHO MIGRATORIO
          </p>
        </section>
        <section
          className={`${styles["derechomigratoriosection2-orientation"]} ${styles["derechomigratoriosection2"]}`}
        >
          <section
            className={`${styles["layer-371-5428df8aee6b48d596744c8d06b3698d-orientation"]} ${styles["layer-371-5428df8aee6b48d596744c8d06b3698d"]}`}
          >
            <section
              className={styles["layer-649-83806fe25a7e4397a69e82635c68ec87"]}
            >
              <section
                className={styles["layer-652-f8816cf22bb74341a6c50bbb5b4cb1d6"]}
              >
                <p
                  className={
                    styles["text-532-c4b6d478f66646a7b521b98db1c51407"]
                  }
                >
                  1.
                </p>
              </section>
              <section
                className={styles["layer-653-b9f444621f144ad780e037b649f79e4d"]}
              >
                <p
                  className={
                    styles["text-533-6513a6a5c301484f9b119ca291c21717"]
                  }
                >
                  Trámite de visas y cédulas de extranjería.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-650-c67564b0ef724e7daa115197b4f7b0d4"]}
            >
              <section
                className={styles["layer-654-e71ddb15a90b432f9b153f4310dccac5"]}
              >
                <p
                  className={
                    styles["text-534-b8d6e81f634342aab89edfd7cc13577c"]
                  }
                >
                  2.
                </p>
              </section>
              <section
                className={styles["layer-655-dadd02af35754d1692128c3d73cbae71"]}
              >
                <p
                  className={
                    styles["text-535-bd4db502a46141608239f7a18e465373"]
                  }
                >
                  Solicitud y trámite de permisos de permanencia.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-651-52ce67d0d34f4051a35ac29dfe5388ac"]}
            >
              <section
                className={styles["layer-656-286e899173c8418da31bb81c219e82ea"]}
              >
                <p
                  className={
                    styles["text-536-9df851b15163487c8f60a155cc8885a5"]
                  }
                >
                  3.
                </p>
              </section>
              <section
                className={styles["layer-657-dfb52a25652b4e2686d8021c8d3037ef"]}
              >
                <p
                  className={
                    styles["text-537-24a3033d07454d99aabd5908b7512801"]
                  }
                >
                  Solicitud y trámite de nacionalidad colombiana por adopción.
                </p>
              </section>
            </section>
          </section>
          <section
            className={`${styles["layer-648-68d20573e02a4ceb93f420e140ef567b-orientation"]} ${styles["layer-648-68d20573e02a4ceb93f420e140ef567b"]}`}
          >
            <section
              className={styles["layer-658-65e65b1b592d48959c3250bb1b836fe2"]}
            >
              <section
                className={styles["layer-665-7c78862ad30b44d1a6decd836b54b54e"]}
              >
                <p
                  className={
                    styles["text-538-6ea098651a9c4962b8ca4b97e92b7349"]
                  }
                >
                  4.
                </p>
              </section>
              <section
                className={styles["layer-666-15eb0ef26662425098a19f501189566c"]}
              >
                <p
                  className={
                    styles["text-539-ec439e0dfdeb4edb9589c3daefb3d010"]
                  }
                >
                  Reportes de vinculación y desvinculación ante Migración
                  Colombia y ante el Ministerio del Trabajo.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-659-b1a725ac905e4dd487a1740199d5c95e"]}
            >
              <section
                className={styles["layer-663-841746c7cf0c4082ba7f8d35ea166c8f"]}
              >
                <p
                  className={
                    styles["text-540-c087a3305f4047109ad6f97177f7d3c3"]
                  }
                >
                  5.
                </p>
              </section>
              <section
                className={styles["layer-664-2b5f0128f09e40c69eb08c52036ca9b9"]}
              >
                <p
                  className={
                    styles["text-541-13c6aa86ee384d25bdd976b4ac4b9291"]
                  }
                >
                  Manejo de trabajadores extranjeros.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-660-8a54567e830f42cd90637ae9b19f9dd8"]}
            >
              <section
                className={styles["layer-661-86e45902613e45d5b29f968339d332bb"]}
              >
                <p
                  className={
                    styles["text-542-891c5e140fe341c89fa0c340c0c8ef23"]
                  }
                >
                  6.
                </p>
              </section>
              <section
                className={styles["layer-662-21e516fa56cd48c39dcd79767702dce9"]}
              >
                <p
                  className={
                    styles["text-543-0040f6d5f88c4b03bf339009be387fef"]
                  }
                >
                  Cumplimiento de obligaciones migratorias.
                </p>
              </section>
            </section>
          </section>
        </section>
        <section
          className={styles["layer-821-e4672b4541dd4c11a2d1f4dc7b3e5b20"]}
        >
          <section
            className={styles["layer-948-276ca5f55c234f01b9b498a8725c5f68"]}
          >
            <section
              className={styles["layer-971-f08dd6871d31412cbe18b2c460aacdf2"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-303-2ce9f008d218437b87760dcc00f38b56"]
                  }
                  alt="imageview-303"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-304-b57e7ec332734da1984ef87f68175be4"]
                  }
                  alt="imageview-304"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-305-ebea9ba3637f4f38baf72ca357449d1d"]
                  }
                  alt="imageview-305"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-306-49dc7bad0a8b4add9b6449db576cc6f2"]
                  }
                  alt="imageview-306"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-972-de21e149839142458e52f2926a9c6bd2"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-835-9a9db24532bb4f0aa63cb4ad1cb91add"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-836-e94b03f7e4c64ecfadcda8c65e3a5dcb"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Derechomigratorio;
