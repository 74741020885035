import React from "react";
import { Link } from "react-router-dom";

import styles from "./derechotributario.module.css";

/**
 * Derechotributario component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Derechotributario component.
 */
const Derechotributario = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Derechotributario}>
        <section className={styles.derechotributarioheader}>
          <Link to="/Home">
            <section
              className={styles["layer-358-22d99567c0224953b1accc6574454d6c"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-377-efc9957a431c4a1697318d807526807a"]
                  }
                  alt="imageview-377"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-359-1c478a790ff54e60bcf6e21950414afd"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-342-e50be2f158544d82a9f482ac70fd5904"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-343-3f7aca6f9d2049cd953f9debc6192eb2"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-344-059bc4cdcbca4dbfbed88c082626ed94"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-345-6ca8962a625949c2b4f36ce992b4f753"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-360-be07bf4245d945288fd56b0c894e3945"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-346-880e6cf2c2b64f6098a8810ddacd8522"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-361-74a2af96b7054e4dbbfc2d37a86e451b"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-15-349301617e1c437680dbbc32d37296e6"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section className={styles.derechotributariosection1}>
          <p className={styles["text-278-dda0d0f19dae4512af636cfdb2ae251b"]}>
            DERECHO TRIBUTARIO
          </p>
        </section>
        <section
          className={`${styles["derechotributariosection2-orientation"]} ${styles["derechotributariosection2"]}`}
        >
          <section
            className={`${styles["layer-1639-de743383905f49ac8e50df20596755b1-orientation"]} ${styles["layer-1639-de743383905f49ac8e50df20596755b1"]}`}
          >
            <section
              className={styles["layer-234-ef76f0f62ce84b9cb60be5c4a312363d"]}
            >
              <section
                className={styles["layer-243-01d425d5f90c47b59310c0504d950ed4"]}
              >
                <p
                  className={
                    styles["text-260-3a05dc3165f340759c3a8ee278f30e7a"]
                  }
                >
                  1.
                </p>
              </section>
              <section
                className={styles["layer-244-f9bd0cbdbc73429ca3253e39d15527f8"]}
              >
                <p
                  className={
                    styles["text-261-36e5e99a2dd6452a8955131a1d985416"]
                  }
                >
                  Tributación nacional e internacional de personas naturales y
                  jurídicas.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-235-e7101809502042468d882ea4153ebd98"]}
            >
              <section
                className={styles["layer-245-179e1e2a827d47d28a86dcd507fc8523"]}
              >
                <p
                  className={
                    styles["text-262-7104603bc2054cbcae9b0124c828a0dd"]
                  }
                >
                  2.
                </p>
              </section>
              <section
                className={styles["layer-246-4b933a4595c04b59977cdf76b6d05c35"]}
              >
                <p
                  className={
                    styles["text-263-589701cdbce44f449358272580f21280"]
                  }
                >
                  Tributación en procesos de adquisiciones, fusiones y
                  escisiones de empresas.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-236-93193a715b1c424f87b8ff2df462e8cb"]}
            >
              <section
                className={styles["layer-247-92cf8d757fe64d288bd6d871f1972ab2"]}
              >
                <p
                  className={
                    styles["text-264-325184179e5a445c9fc523f82ea945ef"]
                  }
                >
                  3.
                </p>
              </section>
              <section
                className={styles["layer-248-69ea89c892e749f6abc616181d909bd6"]}
              >
                <p
                  className={
                    styles["text-265-c01ea86459474dfaba18548ba7ab601e"]
                  }
                >
                  Optimización tributaria y cumplimiento de obligaciones en
                  materia tributaria.
                </p>
              </section>
            </section>
          </section>
          <section
            className={`${styles["layer-1640-88807b8185bf405a9c62722c705436ed-orientation"]} ${styles["layer-1640-88807b8185bf405a9c62722c705436ed"]}`}
          >
            <section
              className={styles["layer-237-beb94b67b81b45539e6ab651e864165e"]}
            >
              <section
                className={styles["layer-249-264f6e68eb6a497284e1a038d36146af"]}
              >
                <p
                  className={
                    styles["text-266-4b0b274665434cf8a52537432e9404a4"]
                  }
                >
                  4.
                </p>
              </section>
              <section
                className={styles["layer-250-978cb54dfe7d473ca3a433f300ae0102"]}
              >
                <p
                  className={
                    styles["text-267-52c548199c86481894339f6b1bc436eb"]
                  }
                >
                  Convenios para Evitar la Doble Imposición.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-238-fb6524e8e9d649ae99b54d4e5ba1e6b3"]}
            >
              <section
                className={styles["layer-251-1094227617e44b0ea9d48c326bf730d2"]}
              >
                <p
                  className={
                    styles["text-268-d87ae52002e644568003210381a47305"]
                  }
                >
                  5.
                </p>
              </section>
              <section
                className={styles["layer-252-77e69c5672194a1bb01e9384bed9c8f1"]}
              >
                <p
                  className={
                    styles["text-269-eb02358f015d44ff8a05322edf9cceef"]
                  }
                >
                  Régimen Simple de Tributación.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-680-ead4ce7dabc54aab862a335578d1b8ad"]}
            >
              <section
                className={styles["layer-253-8111e1d188cd4b519a69e1414c043e50"]}
              >
                <p
                  className={
                    styles["text-270-b3a320eb1e024d948d1c6453295e4d20"]
                  }
                >
                  6.
                </p>
              </section>
              <section
                className={styles["layer-254-f53076f8452a4ab3992ba1a09437a675"]}
              >
                <p
                  className={
                    styles["text-271-f658ddf823f248349fb0fe9babc22817"]
                  }
                >
                  Precios de Transferencia.
                </p>
              </section>
            </section>
          </section>
          <section
            className={`${styles["layer-2009-d0a023972f5648ca8e98805e414e1718-orientation"]} ${styles["layer-2009-d0a023972f5648ca8e98805e414e1718"]}`}
          >
            <section
              className={styles["layer-240-c9b58987915d4b56b9c3a46cf353cb70"]}
            >
              <section
                className={styles["layer-255-814bbcd440f848808ff65f0ac893cc5d"]}
              >
                <p
                  className={
                    styles["text-272-81346a2c52b446b59b763d3fcd55af8a"]
                  }
                >
                  7.
                </p>
              </section>
              <section
                className={styles["layer-256-fc458dd4b19e4455b93a03e3b7cd9d81"]}
              >
                <p
                  className={
                    styles["text-273-301a1c821a8f40fd866c51dc776110a0"]
                  }
                >
                  Litigios en vía administrativa y judicial en materia
                  tributaria.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-241-48600d28cd9144b8b7038d18917c1197"]}
            >
              <section
                className={styles["layer-257-cc910f729bd142cca478dc9b60c5de49"]}
              >
                <p
                  className={
                    styles["text-274-3de573b8d2df478e81d9d414f66038b2"]
                  }
                >
                  8.
                </p>
              </section>
              <section
                className={styles["layer-258-945d078f8f854f8da77219d6f08de642"]}
              >
                <p
                  className={
                    styles["text-275-a6929c09a12745978e95ad2049a2a9c2"]
                  }
                >
                  Gestión Patrimonial de personas naturales y familias.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-242-4527f9a378354bb98abaa7185e5de0d2"]}
            >
              <section
                className={styles["layer-259-b85f830ad979477090ebd1194d9bc7e2"]}
              >
                <p
                  className={
                    styles["text-276-1c13091b1e324128952c9646023f2b36"]
                  }
                >
                  9.
                </p>
              </section>
              <section
                className={styles["layer-260-ca7f5b8fcca74a08aedafcad02a779ba"]}
              >
                <p
                  className={
                    styles["text-277-01613a2e4e374d11a5df29e389de313d"]
                  }
                >
                  Derecho Cambiario.
                </p>
              </section>
            </section>
          </section>
        </section>
        <section
          className={styles["layer-898-4154cb0fc6c34ac7ade29ded504fa962"]}
        >
          <section
            className={styles["layer-1253-0dcd3bb17c82490690c6097aa3dc9756"]}
          >
            <section
              className={styles["layer-976-4b6c62e47c3b41dcae5961110aa0e54a"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-296-42ae8ecd628141d397a78c433bdfa46f"]
                  }
                  alt="imageview-296"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-297-71f617992ee44bf5aee67db2506b27a8"]
                  }
                  alt="imageview-297"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-298-6be85d5e66a842849cb587508d3969b7"]
                  }
                  alt="imageview-298"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-299-e06083202ea34f0d95228ba56e9d13a9"]
                  }
                  alt="imageview-299"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-977-5abac821107e4b348add921d78a48887"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-884-68297c5b27ea4653ad8d1ff3568cf6e0"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-885-8638b72fc71742e09b434907a183377f"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Derechotributario;
