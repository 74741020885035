import React from "react";
import { Link } from "react-router-dom";

import styles from "./victorcabrera.module.css";

/**
 * Victorcabrera component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Victorcabrera component.
 */
const Victorcabrera = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Victorcabrera}>
        <section className={styles.victorcabreraheader}>
          <Link to="/Home">
            <section
              className={styles["layer-162-f76647e063074c859d764029f8bda2eb"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-483-1fae23b2061a4c3a9ef502c2c2e91cdf"]
                  }
                  alt="imageview-483"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-163-78977617be0b4c4dbdfaea47882608c7"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-220-c2fea409058a40949073109cdfc9045d"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-221-f359bd45f2ec4970809b48252909b7e3"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-222-873614e629314188aab31a35ca5f4e4b"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-223-348655e8195f49ffbb5ea4e7da5a2153"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-164-e5bb97ca510148a18390de967d6497eb"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-224-d5fe0b909b204e96a49e243b4880de6d"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-165-2ca61d14d8d540c5968f202e8d796c9e"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-9-7cf9e14c766448118f4edcd6ac12a905"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section className={styles.victorcabrerasection1}>
          <section
            className={styles["layer-83-7d9fa6cbe9b54d56b758f9f3a8a81ef5"]}
          >
            <p className={styles["text-64-19bae4fb3d5a42b7be4c364981e4f025"]}>
              Victor Cabrera
            </p>
            <section
              className={styles["layer-653-273072f67a5541b5b0c0f1f9fa19a699"]}
            >
              <img
                className={
                  styles["imageview-255-357d0e52d18e4538a15ea8036571be1e"]
                }
                alt="imageview-255"
                src="/assets/Victor Cabrera - Square.png"
              />
            </section>
            <p className={styles["text-65-b10316973c2b4f51ab72e1f85f86f739"]}>
              Laboral y Migratorio
            </p>
            <p
              className={styles["text-66-653e3e4b2269463a8402af8771efe881"]}
              onClick={() =>
                openInNewTab("mailto:victor.cabrera@bavieralegal.com")
              }
            >
              victor.cabrera@bavieralegal.com
            </p>
            <picture>
              <img
                className={
                  styles["imageview-37-511362cd79b7487fbb8ebf02544bba6c"]
                }
                alt="imageview-37"
                src="/assets/linkedin-Blue.png"
                onClick={() =>
                  openInNewTab(
                    "https://www.linkedin.com/in/victor-manuel-cabrera-arteaga/",
                  )
                }
              />
            </picture>
          </section>
        </section>
        <section className={styles.victorcabrerasection2}>
          <section
            className={styles["layer-84-b66c3e530a484cf8b460bfb697558d0d"]}
          >
            <p className={styles["text-466-4426ab34186b4a93acd15d9c7790bbb5"]}>
              Victor es estudiante de Derecho de la Universidad Externado de
              Colombia en proceso de grado. En la Universidad, Victor participó
              en el Seminario de Derecho Laboral, y realizó una profundización
              académica en derecho disciplinario, internet e innovación digital.{" "}
            </p>
            <p className={styles["text-467-e8db500f5a2048c48b8fa20d67f7565d"]}>
              En la actualidad, Victor hace parte del Equipo Laboral y
              Migratorio, asistiendo en consultoría en derecho laboral y
              seguridad social, litigios laborales y de seguridad social,
              proyección y respuesta de acciones de tutela, derechos de
              petición, procesos disciplinarios, trámites migratorios,
              contratación y terminación de contratos laborales, y en general,
              en la gestión contractual de relaciones laborales.
            </p>
            <p className={styles["text-69-95c8e765e8cc4df69e9e4d334fa9dcd2"]}>
              Victor habla español e inglés, participó en un diplomado en
              Derecho de Insolvencia y Crisis Empresarial, y en otro diplomado
              en Derecho, Tecnología e Innovación. Además, ha realizado
              publicaciones relacionadas con temas de derecho laboral y
              seguridad social en Baviera Legal, y ha publicado en la Cámara de
              Comercio de Bogotá.
            </p>
          </section>
        </section>
        <section
          className={styles["layer-1013-1c754184cdcd419a95cb593d5d8129a2"]}
        >
          <section
            className={styles["layer-1124-1c9d39f2ae2a4f82823eda8da9493e0e"]}
          >
            <section
              className={styles["layer-160-53ba65cbbba24b4aba8db6fd8c2b78fe"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-342-30ce53719c1542a5b3868cea6505a8df"]
                  }
                  alt="imageview-342"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-343-6e04a094f0fd41da8cecbf26f498d034"]
                  }
                  alt="imageview-343"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-344-662ff4aae3d84ce8976c996528a85441"]
                  }
                  alt="imageview-344"
                  src="/assets/email.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-345-cb55caffd4c84f71ad41f3f007e6992e"]
                  }
                  alt="imageview-345"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-161-0c01ec5f527d461baa40f63857a6c346"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-218-fa4bc7aaf53e49c183a011c42e68a645"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-219-8af7f286e30746b08eb6729ca903addc"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Victorcabrera;
