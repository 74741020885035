import React from "react";
import { Link } from "react-router-dom";

import styles from "./derecholaboral.module.css";

/**
 * Derecholaboral component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Derecholaboral component.
 */
const Derecholaboral = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Derecholaboral}>
        <section className={styles.derecholaboralheader}>
          <Link to="/Home">
            <section
              className={styles["layer-541-15a2b5261a0d451d8b59b605d8612f5c"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-359-78ec7fec2467409fb42973ad6037345a"]
                  }
                  alt="imageview-359"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-542-f9e00d72224c4ee481a17c53a4b68dcf"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-486-4277b7e658554f4a9889cdc300e2c53b"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-487-c04a1a49b34643aa80dd0add731dbe43"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-447-79787ecc48eb41798eb1aae3ecc4401c"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-489-384623175c3249fba602cecb64af2147"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-543-ce1c0f49f35f43799a6d4abfdef02665"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-490-40ac392a026942d693029ef5b2ebaaf8"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-544-3487d7cd5edd45fd8204a1a499fc4af8"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-19-c6304fc0e2ff41ab9a4983bf062ee9b5"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section className={styles.derecholaboralsection1}>
          <p className={styles["text-368-0d86958f27e3481dab8df63e1eddbcf2"]}>
            DERECHO LABORAL Y SEGURIDAD SOCIAL
          </p>
        </section>
        <section className={styles.derecholaboralsection2}>
          <section
            className={`${styles["layer-728-3174e0a1485f4d09809f1ff14003b383-orientation"]} ${styles["layer-728-3174e0a1485f4d09809f1ff14003b383"]}`}
          >
            <section
              className={styles["layer-324-394d6fdfa2ae4db893f478d97089fe7f"]}
            >
              <section
                className={styles["layer-345-2b125b5a3f0942abb9ea5ea50836c365"]}
              >
                <p
                  className={
                    styles["text-391-478a1ca2d7394698a2cfe45ad123163a"]
                  }
                >
                  1.
                </p>
              </section>
              <section
                className={styles["layer-346-26f119dea52247f1834260cccfcef3e7"]}
              >
                <p
                  className={
                    styles["text-392-df6ef76710bf43c0ba048723b0bc0efa"]
                  }
                >
                  Vinculación de personal.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-325-e1e8d248cb4b42fca1c9bdd6e51e509b"]}
            >
              <section
                className={styles["layer-347-fffeb8891f5649ceb226f1aab9403ed7"]}
              >
                <p
                  className={
                    styles["text-393-35b7149a4bf648b4838001534c68e093"]
                  }
                >
                  2.
                </p>
              </section>
              <section
                className={styles["layer-348-5fd15322d55641bf9c6d3c03306057b6"]}
              >
                <p
                  className={
                    styles["text-394-bc4b9c70468d4f48a6a54adebe4bd296"]
                  }
                >
                  Redacción de modelos de contratos de trabajo para diferentes
                  cargos.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-326-9f066b9be7ac4e49b27778c3dff08254"]}
            >
              <section
                className={styles["layer-349-d87e5fb9fe2548b48672e04ea36a8377"]}
              >
                <p
                  className={
                    styles["text-395-3012551ba64447e8aa930e8f7384e0fa"]
                  }
                >
                  3.
                </p>
              </section>
              <section
                className={styles["layer-350-6d4436d65d6740788de86909395f7dbd"]}
              >
                <p
                  className={
                    styles["text-396-d6ece14d854c4adda52b279b9c3c727a"]
                  }
                >
                  Teletrabajo, trabajo en casa y trabajo remoto.
                </p>
              </section>
            </section>
          </section>
          <section
            className={`${styles["layer-729-9ca064655cef41fbbf27dffb442d132a-orientation"]} ${styles["layer-729-9ca064655cef41fbbf27dffb442d132a"]}`}
          >
            <section
              className={styles["layer-327-e661002f00054328ab4daebbee984cc2"]}
            >
              <section
                className={styles["layer-351-11bdafdd2bd5435bb5997f86fe7ae4ba"]}
              >
                <p
                  className={
                    styles["text-397-525489846c5f4d52a3d26a5730a36a01"]
                  }
                >
                  4.
                </p>
              </section>
              <section
                className={styles["layer-352-c93f94abf67d4789b4f99b1005a6d8c9"]}
              >
                <p
                  className={
                    styles["text-398-24b90db859a342fa96cc324b45a84c3f"]
                  }
                >
                  Procesos disciplinarios.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-328-8b5bb76742034ded8f32b7d63ae09854"]}
            >
              <section
                className={styles["layer-353-17c4729b9c0e4d2085718c2760c7404f"]}
              >
                <p
                  className={
                    styles["text-399-2f54de83a245432cb028570e13dba9ee"]
                  }
                >
                  5.
                </p>
              </section>
              <section
                className={styles["layer-354-6a0744114643414b8034cd4b95ba2a5b"]}
              >
                <p
                  className={
                    styles["text-400-bab999ebcb99475e8adbcbe6c8914884"]
                  }
                >
                  Manejo de incapacidades.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-329-c9a90b1f2e99419d856fcf6f4dfa15d2"]}
            >
              <section
                className={styles["layer-355-e73b2d1302f84b7991c08f1dc309f13b"]}
              >
                <p
                  className={
                    styles["text-404-03f5a92637a745a88fb9cb4b16550594"]
                  }
                >
                  6.
                </p>
              </section>
              <section
                className={styles["layer-356-c0a6f85c507842449dad5d4790b11276"]}
              >
                <p
                  className={
                    styles["text-405-483f21e555ba43039f7e60271d82f655"]
                  }
                >
                  Esquemas de jornada laboral.
                </p>
              </section>
            </section>
          </section>
          <section
            className={`${styles["layer-732-40cd36593d214e4c8985f8862714d11c-orientation"]} ${styles["layer-732-40cd36593d214e4c8985f8862714d11c"]}`}
          >
            <section
              className={styles["layer-330-bac6105a810c4bc6995345b407f5fb83"]}
            >
              <section
                className={styles["layer-357-5519b3015c0a4021a1be74bad404e4d6"]}
              >
                <p
                  className={
                    styles["text-403-339bb4ad662a4a05b167385c83cf9c72"]
                  }
                >
                  7.
                </p>
              </section>
              <section
                className={styles["layer-358-89b12de8d68e4288a428702fcd1d8fc2"]}
              >
                <p
                  className={
                    styles["text-406-8aff0fb14a9d489a8896ee2046a71264"]
                  }
                >
                  Estructura de remuneración de trabajadores, incluyendo
                  estructuración de beneficios extralegales no constitutivos de
                  salario y esquemas de desalarización.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-331-9ea51973a6a245f59060b17ec48b34f9"]}
            >
              <section
                className={styles["layer-359-9c607b6cf92641dc93178e06b5f47ea6"]}
              >
                <p
                  className={
                    styles["text-407-72dd9635dd844df09ee6607d9fe2b9bc"]
                  }
                >
                  8.
                </p>
              </section>
              <section
                className={styles["layer-360-7608e6de181c4f1390ac61456c9e932c"]}
              >
                <p
                  className={
                    styles["text-408-f09b768c1ccb40a3ab49ea84c39c0aae"]
                  }
                >
                  Disminución de costos laborales.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-332-d20db1769c8a4829b1a8b93ffc78c9dd"]}
            >
              <section
                className={styles["layer-361-b12477f9f96849cd88a8502fa433e806"]}
              >
                <p
                  className={
                    styles["text-409-a9483ad671ec42b1a15d1edcfd74fe48"]
                  }
                >
                  9.
                </p>
              </section>
              <section
                className={styles["layer-362-a5ddfeae41da4df4a3a1f820c10004c8"]}
              >
                <p
                  className={
                    styles["text-410-90c084561d1440a089a050b8f186b9fd"]
                  }
                >
                  Reglamento interno de trabajo y políticas laborales.
                </p>
              </section>
            </section>
          </section>
          <section
            className={`${styles["layer-323-58f1ae8049a447b0a28a03c7b8c97826-orientation"]} ${styles["layer-323-58f1ae8049a447b0a28a03c7b8c97826"]}`}
          >
            <section
              className={styles["layer-333-95e37c3dc2324906a1337469225fb833"]}
            >
              <section
                className={styles["layer-363-81c7fbc4c42d44878dd49ae90e2acd42"]}
              >
                <p
                  className={
                    styles["text-401-caaea98296294b418f992140886f21d4"]
                  }
                >
                  10.
                </p>
              </section>
              <section
                className={styles["layer-364-6dda8ef5f6ef49ac8898d2c272767eeb"]}
              >
                <p
                  className={
                    styles["text-402-e55ece3960b94ea7b3b5e5b5f3a2f1d9"]
                  }
                >
                  Procesos de desvinculación, revisión y ajuste de liquidación
                  final de acreencias laborales, y documentos de retiro.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-334-17bc6aaa79ce4e4ab9dcde96b35cb5e6"]}
            >
              <section
                className={styles["layer-365-e3a3cf695ee242f78a4b4e52620a6e4a"]}
              >
                <p
                  className={
                    styles["text-390-d637af9ad06e4362bbbf45c790f93a25"]
                  }
                >
                  11.
                </p>
              </section>
              <section
                className={styles["layer-366-28f40a5e17374c6db0d96e7c23a45090"]}
              >
                <p
                  className={
                    styles["text-389-5561b14ec64f4462bf77d54959a46b58"]
                  }
                >
                  Conciliaciones y transacciones laborales.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-551-79e4ff4c227949d58301920cd94f2998"]}
            >
              <section
                className={styles["layer-367-aabda1705cfb492aae7f0d87662ce363"]}
              >
                <p
                  className={
                    styles["text-388-ef8541973c3643ca8fb6e67d9cb7ecf0"]
                  }
                >
                  12.
                </p>
              </section>
              <section
                className={styles["layer-368-5b28f4145856469595031d38d9d38a19"]}
              >
                <p
                  className={
                    styles["text-387-d6fa501e8eb244df835b251aad8c2e8f"]
                  }
                >
                  Manejo de relaciones con contratistas independientes y
                  empresas de servicios temporales.
                </p>
              </section>
            </section>
          </section>
          <section
            className={`${styles["layer-733-5c8fd1d39d3949b7a4e1ece7bb0b1db0-orientation"]} ${styles["layer-733-5c8fd1d39d3949b7a4e1ece7bb0b1db0"]}`}
          >
            <section
              className={styles["layer-336-277515f5819d4e868aa5410102ef10d2"]}
            >
              <section
                className={styles["layer-369-57ab494830454c5e8378d017239c552c"]}
              >
                <p
                  className={
                    styles["text-386-58f5db29869e47e68c0d70b03c0179eb"]
                  }
                >
                  13.
                </p>
              </section>
              <section
                className={styles["layer-370-e1db84f8416a472eab2d8ea6432962e8"]}
              >
                <p
                  className={
                    styles["text-385-96d93fdb19784f9db1a6de42965923b5"]
                  }
                >
                  Litigios laborales.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-337-dec8ac18262240439b3f9402d4fd55bf"]}
            >
              <section
                className={styles["layer-371-244c72fa8bfc4f508f810acbd8be3b06"]}
              >
                <p
                  className={
                    styles["text-384-1ff7c8c3c0bb403a974232746d2c3f46"]
                  }
                >
                  14.
                </p>
              </section>
              <section
                className={styles["layer-372-5f8729e4c43b4786b8054f6c83af1347"]}
              >
                <p
                  className={
                    styles["text-383-44f690d2b3b24e3bbcdc2e54863f7f93"]
                  }
                >
                  Derecho laboral colectivo.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-338-c6ab728bd9be4dfe9b39930bb1af93c4"]}
            >
              <section
                className={styles["layer-373-d32404e5708f47c098661b15537de7b8"]}
              >
                <p
                  className={
                    styles["text-382-9b931890734d44c791ca1edb0bf9e391"]
                  }
                >
                  15.
                </p>
              </section>
              <section
                className={styles["layer-374-130a709b86f84a6cbdf72e5adaefb2bf"]}
              >
                <p
                  className={
                    styles["text-381-e93365f1037d4a7895a4e8f1dc3b5178"]
                  }
                >
                  Procesos UGPP y beneficios tributarios.
                </p>
              </section>
            </section>
          </section>
          <section
            className={`${styles["layer-734-262f1cbfb0154413a569d893aa8e0440-orientation"]} ${styles["layer-734-262f1cbfb0154413a569d893aa8e0440"]}`}
          >
            <section
              className={styles["layer-339-08a2940082ce42eeb4f40888a1fbeca6"]}
            >
              <section
                className={styles["layer-375-a8d22a91c0f54f54b7b778d4488cd874"]}
              >
                <p
                  className={
                    styles["text-380-3813d7eaa28e4e4d8b80bdb087975b86"]
                  }
                >
                  16.
                </p>
              </section>
              <section
                className={styles["layer-376-dee06bcecd3349a8b7eeb4c2283cd61a"]}
              >
                <p
                  className={
                    styles["text-379-e48061a5d05f43f7933319630b217962"]
                  }
                >
                  Manejo de comité de convivencia y acoso laboral.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-340-181a5d0b6d0c4596a22947ba6595e1d9"]}
            >
              <section
                className={styles["layer-377-e926b332375f4f1e8f14a3583114d9b8"]}
              >
                <p
                  className={
                    styles["text-378-a59ff831cb8e448da9541db0633da28a"]
                  }
                >
                  17.
                </p>
              </section>
              <section
                className={styles["layer-378-7469ad3243074257babc078698b156cf"]}
              >
                <p
                  className={
                    styles["text-377-3f4657f1ef5141d287aade9721f438dc"]
                  }
                >
                  Manejo de trabajadores con protección especial por razones de
                  salud, maternidad, entre otros.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-341-b1f77dccb1bb474787700c1eaf1632e5"]}
            >
              <section
                className={styles["layer-379-ecc2f8965a86481a82dc228f0e8fa820"]}
              >
                <p
                  className={
                    styles["text-376-30c5ce3db5594961bc67be2324b1a208"]
                  }
                >
                  18.
                </p>
              </section>
              <section
                className={styles["layer-380-28b3a58f305e43aca56261bd9b66eb57"]}
              >
                <p
                  className={
                    styles["text-375-05c487261def4117bf511c8156609477"]
                  }
                >
                  Procesos administrativos y trámites ante el Ministerio del
                  Trabajo.
                </p>
              </section>
            </section>
          </section>
          <section
            className={`${styles["layer-1791-ff640f2542f24c47b3901ecd700ec332-orientation"]} ${styles["layer-1791-ff640f2542f24c47b3901ecd700ec332"]}`}
          >
            <section
              className={styles["layer-342-d59c736b360f402d92c7bf0baa25339e"]}
            >
              <section
                className={styles["layer-381-9e54a17f864c41daa47ff728090d335b"]}
              >
                <p
                  className={
                    styles["text-374-9dfa0c0e91fb491585ffac5dcd861185"]
                  }
                >
                  19.
                </p>
              </section>
              <section
                className={styles["layer-382-e264b91f9ac74e6284b006467841a47a"]}
              >
                <p
                  className={
                    styles["text-373-ffc2c4e0ce1e41778354ea8005acce10"]
                  }
                >
                  Cumplimiento de obligaciones laborales.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-343-5d9f83de04d349c7a6f6aaf301e38434"]}
            >
              <section
                className={styles["layer-383-cd8fd03927994133b2a62387735ecca4"]}
              >
                <p
                  className={
                    styles["text-372-962cd3a8307f4a72b6894eb132772c23"]
                  }
                >
                  20.
                </p>
              </section>
              <section
                className={styles["layer-384-608ae2db9c16451abfa7ebf82a4e0c8a"]}
              >
                <p
                  className={
                    styles["text-371-4c59576c77fe450e8167994fdd597c1b"]
                  }
                >
                  Auditorías laborales y de seguridad social.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-344-0bf2c9171a654a78808759c36d4f5be6"]}
            >
              <section
                className={styles["layer-385-48e7ca6f26d94afd83ee12db749174e3"]}
              >
                <p
                  className={
                    styles["text-370-9dfb712fde3049348085bcc83b9399fa"]
                  }
                >
                  21.
                </p>
              </section>
              <section
                className={styles["layer-386-97af1600eb22480aaed50be671e912c4"]}
              >
                <p
                  className={
                    styles["text-369-e60db33ed4374cb982e2b45b136e8d45"]
                  }
                >
                  Auditorías laborales y de seguridad social.
                </p>
              </section>
            </section>
          </section>
          <section
            className={styles["layer-735-bf039933831148ba9a81d5615b89ecf7"]}
          >
            <section
              className={styles["layer-387-6ef1a81001d24ffabd63872ef70751b9"]}
            >
              <section
                className={styles["layer-388-904e00f9a1b94b3db7d9c2d07cc28535"]}
              >
                <p
                  className={
                    styles["text-411-f6ee45a0272b4187bf51b135c3b7f9f5"]
                  }
                >
                  22.
                </p>
              </section>
              <section
                className={styles["layer-389-7e91472d33cf4f39807247f89fbe3028"]}
              >
                <p
                  className={
                    styles["text-412-9d15a7f6125d4538a58b843a56d53efb"]
                  }
                >
                  Auditorías laborales y de seguridad social.
                </p>
              </section>
            </section>
          </section>
        </section>
        <section
          className={styles["layer-790-23c655743ef64e43a535e21dfb48f472"]}
        >
          <section
            className={styles["layer-917-8c95c39b7c5e43ceb2583e335ffd212b"]}
          >
            <section
              className={styles["layer-1544-aec6bc950c6d450ab11979a266711353"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-294-b568529c834347ad940b2dcbdf668c9d"]
                  }
                  alt="imageview-294"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-295-a2c158939582425e891828b25738b833"]
                  }
                  alt="imageview-295"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-296-54ecc07adef74a049d3336066352af70"]
                  }
                  alt="imageview-296"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-297-d3b4bc6fd8b94b359b51c86bdb152911"]
                  }
                  alt="imageview-297"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-1545-c2fdc9f651fa4ec197e50dccbd1f1342"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-1317-189865232b96486ea4f9dad16932b7cb"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-1318-af3913d5ad1e48ee871b14e314e4a965"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Derecholaboral;
