import React from "react";
import { Link } from "react-router-dom";

import styles from "./mariapaulasuarez.module.css";

/**
 * Mariapaulasuarez component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Mariapaulasuarez component.
 */
const Mariapaulasuarez = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Mariapaulasuarez}>
        <section className={styles.mariapaulasuarezheader}>
          <Link to="/Home">
            <section
              className={styles["layer-358-ec09b29029be4a7fb9b9da4a4ea044a9"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-424-3c45bb3efd7248239ddff07c7d29ee83"]
                  }
                  alt="imageview-424"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-359-2046ccbe99434a76ad5e4e18588922ee"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-430-784dda8b34a44974b629e04c8938cf5b"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-431-8a3e8a147f774a1eb31a21752c55da6b"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-432-0064ca0802ba475fbba15f0f8de73331"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-433-f2e7ea5c37d44eb88cbe5857e1654dec"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-360-243fd8cce0ca48bcab300875b44cbba1"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-434-648c11cf40674e9fba17540560008c50"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-361-39585b87e5b741e588985d47a03b5cb4"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-23-741d58c4c4ef4202831b864da15a99a6"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section className={styles.mariapaulasuarezsection1}>
          <section
            className={styles["layer-356-34e22c53a54b453fb130bb8ba7b58783"]}
          >
            <p className={styles["text-424-c7a242995bfc48eca15d027fb56890b2"]}>
              María Paula Suárez
            </p>
            <section
              className={styles["layer-718-a6716922ac72435e979f6769f8b11bce"]}
            >
              <img
                className={
                  styles["imageview-293-3d81e259e2ee44cea9f61e321ac5674c"]
                }
                alt="imageview-293"
                src="/assets/Maria Paula Suarez - Square.png"
              />
            </section>
            <p className={styles["text-425-468b383990854b9ea13aec5c4f4c3e51"]}>
              Laboral y Migratorio
            </p>
            <p
              className={styles["text-426-93764309ecc74eba882ba3f3a676afde"]}
              onClick={() =>
                openInNewTab("mailto:maria.suarez@bavieralegal.com")
              }
            >
              maria.suarez@bavieralegal.com
            </p>
            <picture>
              <img
                className={
                  styles["imageview-195-8678ea65eaac4f7096ced3112e152c58"]
                }
                alt="imageview-195"
                src="/assets/linkedin-Blue.png"
                onClick={() =>
                  openInNewTab(
                    "https://www.linkedin.com/in/mar%C3%ADa-paula-su%C3%A1rez-isaza-58449a15b/",
                  )
                }
              />
            </picture>
          </section>
        </section>
        <section className={styles.mariapaulasuarezsection2}>
          <section
            className={styles["layer-357-d0c48b07d8744e4098f76507ebafe684"]}
          >
            <p className={styles["text-427-4afb2db17c07458d82590022ac7dbb08"]}>
              María Paula es estudiante de Jurisprudencia de la Universidad del
              Rosario, en la cual hace parte del Semillero de Investigación en
              Seguridad Social y Derecho del Trabajo, es monitora de la clase de
              Derecho Individual del Trabajo, y hace parte del Consultorio
              Jurídico en las áreas de derecho laboral y derecho administrativo.
            </p>
            <p className={styles["text-428-d09f80b28bdb4ff0b14e53f8b5d8e4d7"]}>
              En Baviera, María Paula hace parte del Equipo Laboral y
              Migratorio, asistiendo en diversos temas laborales y de seguridad
              social como demandas y litigios laborales, proyección y respuesta
              de acciones de tutela, procesos disciplinarios, vinculación y
              terminación de contratos de trabajo, derechos de petición,
              estructuras de compensación y beneficios, negociación con empresas
              de servicios temporales, procesos de fiscalización ante la UGPP,
              trámites de visas y cédulas de extranjería, entre otros.
            </p>
            <p className={styles["text-429-01a549cb3a3c4f66965eb2d762dcde61"]}>
              María Paula habla español e inglés, participó en un diplomado en
              Inglés legal para Negocios en la Universidad de los Andes, ha
              realizado publicaciones relacionadas con temas de derecho laboral
              y seguridad social en Baviera Legal, y ha publicado en la Cámara
              de Comercio de Bogotá.
            </p>
          </section>
        </section>
        <section
          className={styles["layer-950-f634eb808053420b83b6f924c08c4bc7"]}
        >
          <section
            className={styles["layer-1278-ab3954c8a5f94b7cab5fc9ec277d80f1"]}
          >
            <section
              className={styles["layer-1279-26fd37d2452444a7a00496ba074650e8"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-431-290925f44e2d4533acae26e688bf0f15"]
                  }
                  alt="imageview-431"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-432-3aa247821d0042a4ad033f5bd9659896"]
                  }
                  alt="imageview-432"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-433-14048161c4a9439987226b99aa20467a"]
                  }
                  alt="imageview-433"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-434-6087d8bc63174fefad8d7c708b70da55"]
                  }
                  alt="imageview-434"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-521-a4d94179e5874329afb3ddbb650091d4"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-505-775ef2dfd7a04e14b1d592afe56f0669"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-506-34f4e1b174154d629a94e6a0f71a6efc"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Mariapaulasuarez;
