import React from "react";
import { Link } from "react-router-dom";

import styles from "./julianpena.module.css";

/**
 * Julianpena component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Julianpena component.
 */
const Julianpena = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Julianpena}>
        <section className={styles.julianpenaheader}>
          <Link to="/Home">
            <section
              className={styles["layer-370-d48dd936986c4bbfa646031a96180ed7"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-416-53dd86877ea448d3867134c15b8963a4"]
                  }
                  alt="imageview-416"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-371-5ac2bc4df7414286ba177a2db390793f"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-440-852bf559766644c5ac24e94ea3e41a9a"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-441-d2df316e362c4cfa95329f1862d4a1cf"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-442-435ef67a4ec54b44ac7d919a82775069"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-443-22108fdcb83044b1982bc1ed7d4fc14f"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-372-814070e7625741debdf42a5324aa5dea"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-444-0e88e1433ae444d594003d86ad34bc8c"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-373-7142c7fd2c5342b7b12ea08509e392f6"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-24-d120992ce8af4adcb4875ed852a97b00"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section className={styles.julianpenasection1}>
          <section
            className={styles["layer-368-d58a84d5f9814404b15a4b0e024a0ebb"]}
          >
            <p className={styles["text-437-3d05c855d4d04a7eac4e6736a24a03ae"]}>
              Julián Peña
            </p>
            <section
              className={styles["layer-512-12a2cb5162054ebe840a701884f1b114"]}
            >
              <img
                className={
                  styles["imageview-171-cd4a7e05ce524775841354a2d013b5cc"]
                }
                alt="imageview-171"
                src="/assets/Julian Pena - Square.png"
              />
            </section>
            <p className={styles["text-438-dfae31dfbe324800bd896d6cb9e6df92"]}>
              Tributario
            </p>
            <p
              className={styles["text-439-16198e9eb585425fafb318eaa84ad3bf"]}
              onClick={() =>
                openInNewTab("mailto:julian.pena@bavieralegal.com")
              }
            >
              julian.pena@bavieralegal.com
            </p>
            <picture>
              <img
                className={
                  styles["imageview-202-47d8b7d3fd114d359e1eef0477b52f82"]
                }
                alt="imageview-202"
                src="/assets/linkedin-Blue.png"
                onClick={() =>
                  openInNewTab(
                    "https://www.linkedin.com/in/juli%C3%A1n-andr%C3%A9s-pe%C3%B1a-perdomo-8677b459/",
                  )
                }
              />
            </picture>
          </section>
        </section>
        <section className={styles.julianpenasection2}>
          <section
            className={styles["layer-369-53b4b0d2ae214e10a5f00d469dace51d"]}
          >
            <p className={styles["text-445-c7933ae5a9d3446ea307df73fea566f6"]}>
              Julián es abogado, economista, especialista en tributación y
              magíster en tributación de la Universidad de los Andes.
              Actualmente cuenta con más de 10 años de experiencia, e hizo parte
              de la práctica de impuestos en reconocidas firmas de abogados
              tales como EY, Philippi Prietocarrizosa Ferrero DU & Uría, Posse
              Herrera Ruiz y Lloreda Camacho. En esta última lideró el área de
              impuestos.
            </p>
            <p className={styles["text-446-06f4dac3dca44a1e834d2e60c1a9692f"]}>
              A lo largo de su carrera, Julián ha asesorado en gestión
              patrimonial y tributación nacional e internacional a personas
              naturales, a familias y a empresas nacionales e internacionales.
            </p>
            <p className={styles["text-447-07fcd5fbeabe412687cea49aec9f5c06"]}>
              Julián habla español, inglés y francés, y lidera el área de
              impuestos en Baviera.
            </p>
          </section>
        </section>
        <section
          className={styles["layer-937-d76bae1159c341fc9ba07c1f3e896b0a"]}
        >
          <section
            className={styles["layer-1321-91cba5c196814a2e84cd9f188efa784e"]}
          >
            <section
              className={styles["layer-532-fbe82694d45e45769b5c9c77fe278929"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-456-4bfb111d4d384cc68eb10ef2500a49d7"]
                  }
                  alt="imageview-456"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-457-a89d74d74b4c4cae96b6247e7c225b3f"]
                  }
                  alt="imageview-457"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-458-15a45109966941df88a97e1011ca19d7"]
                  }
                  alt="imageview-458"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-459-00a08de669a74271902c9c2dfb4b8a3d"]
                  }
                  alt="imageview-459"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-533-d6699f57c41a4ea99108fa7bb034614c"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-518-f0d5f267d6024778a2e6379dd5bced94"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-519-aed958689e0c4260ae3e70f8430eb2fb"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Julianpena;
