import React from "react";
import { Link } from "react-router-dom";
import IframeComponent from "../../components/iframeComponent/iframeComponent.js";

import styles from "./agendatucapactitacion.module.css";

/**
 * Agendatucapactitacion component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Agendatucapactitacion component.
 */
const Agendatucapactitacion = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Agendatucapactitacion}>
        <section className={styles.agendatucapactitacionheader}>
          <Link to="/Home">
            <section
              className={styles["layer-919-daed3b06d62d49dfa297a2c4eeecd147"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-326-4c682f5e980244d69d2e15a81de3f36c"]
                  }
                  alt="imageview-326"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-508-827a1197fbae4d74a2f9fef610753de1"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-484-509e3014cedf45459e08922c7d291f4d"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-485-a6e8a5984ec1426d9ef7b55760b55035"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-486-32d1505412fd4f018b4463cd478f4339"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-487-170edb9fbb6b4feba54955eb4f63c372"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-509-89cb91bb7a7f4bfdac29dba848d39ddb"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-483-cd400f7174aa44c1ad298c8af1b83104"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-510-5db3b1c0286b4f87a9359c85ded3821f"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-31-154e77a6693a4e9badd37b4f445f0583"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section className={styles.agendatucapactitacionsection1}>
          <IframeComponent
            width={"100%"}
            height={"100%"}
            sourceURL={
              "https://calendly.com/bavieralegal/baviera-legal-capacitacion-laboral"
            }
          />
        </section>
        <section
          className={styles["layer-669-a55c66e32f8541a5809b2b5b80db4c02"]}
        >
          <section
            className={styles["layer-1528-dfbc466134cf418e99563fb059625d8b"]}
          >
            <section
              className={styles["layer-478-a78eb289a8594c9b962b70dd7fcf18c6"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-157-61e282bbf5e846e295ee6eda8cc1ea92"]
                  }
                  alt="imageview-157"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-158-56a6b6a9714945548a0259091d164c92"]
                  }
                  alt="imageview-158"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-159-f84848aea0a44e339f75ef07d38e0e83"]
                  }
                  alt="imageview-159"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-160-015d49406a944c5f9b83d609555935aa"]
                  }
                  alt="imageview-160"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-479-4d995c65a67743a8b3127a77cd223fc6"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-431-6cd5f92e0a474fe7913347225e3f4505"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-432-95b0af126e9442279ea5bdbec1b07fa9"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Agendatucapactitacion;
