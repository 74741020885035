import React from "react";
import { Link } from "react-router-dom";

import styles from "./nosotros.module.css";

/**
 * Nosotros component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Nosotros component.
 */
const Nosotros = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Nosotros}>
        <header className={styles.nosotrosheader}>
          <Link to="/Home">
            <section
              className={styles["layer-190-7275e2d17eec41bab67ef9b42c21db93"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-185-abdc99b8c6af4650a6aaffb104266141"]
                  }
                  alt="imageview-185"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-191-fbeb0b5af3e74b008ff14bf9fcc32aa3"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-168-7efe370e236b42bda831d553611dd2f7"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-169-a0a6c0fa86f44722a5d1ae8e8488b920"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-170-3999ae8144cd4b7abea16cd8e2958d5b"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-171-d6e1ee4690a245eebe1e2e83a11a3394"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-192-8fc0dbb345d3493cbb9a5fc38c11383a"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-172-84e58c0ab1e14e6e95f7f3c2bcb357c1"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-193-eec0666785694219a877da91dfa34558"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-7-65ad13bb97d0486e8f38a20ceea6b072"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </header>
        <section className={styles.nosotrossection1}>
          <p className={styles["text-426-bb6fa25c8b9b43cf8e3335c309540633"]}>
            ¿POR QUÉ NOSOTROS?
          </p>
        </section>
        <section className={styles.nosotrossection2}>
          <section
            className={`${styles["layer-464-416175c480aa485990e41800c75082ec-orientation"]} ${styles["layer-464-416175c480aa485990e41800c75082ec"]}`}
          >
            <section
              className={styles["layer-187-6bcbaee1c9d5475a8ede36e24ba7c695"]}
            >
              <section
                className={styles["layer-592-3fce799a69994a46b0fcd13bbe9fd8cd"]}
              >
                <img
                  className={
                    styles["imageview-229-9658179f1616425f98a18a4b2fd6a034"]
                  }
                  alt="imageview-229"
                  src="/assets/Experiencia  - Square.png"
                />
              </section>
              <p
                className={styles["text-160-47a2c8aa7e034a2d906376735707daf6"]}
              >
                EXPERIENCIA
              </p>
              <p
                className={styles["text-161-4fd3fe7b7d9f4ae9a6004ecc5f300454"]}
              >
                Trayectoria en las firmas más reconocidas del país y del mundo
                asesorando clientes de distintos sectores económicos y asumiendo
                sus retos.
              </p>
            </section>
            <section
              className={styles["layer-188-153233a9e0f24dec8d8f5799e69a6af1"]}
            >
              <section
                className={styles["layer-593-c8a86431bf5942f2952420c34f68a016"]}
              >
                <img
                  className={
                    styles["imageview-230-70c2857d9bb04d30ac044b3f0b83ba99"]
                  }
                  alt="imageview-230"
                  src="/assets/Innovacion  - Square.png"
                />
              </section>
              <p
                className={styles["text-162-74602e861547418c8fd00bd5f447801c"]}
              >
                INNOVACIÓN
              </p>
              <p
                className={styles["text-163-03b995b4abf34932bb4d0cd51615e96e"]}
              >
                Transformamos y adaptamos nuestra experiencia en las firmas
                tradicionales a las dinámicas del mundo moderno y de su
                contexto.
              </p>
            </section>
            <section
              className={styles["layer-189-399dddfa2daf4cfebb25eb022b0df987"]}
            >
              <section
                className={styles["layer-506-8bf790482fa84c70b582a5222ad8ab50"]}
              >
                <img
                  className={
                    styles["imageview-177-a86bc788825a4dcdb9b7de5465e0c955"]
                  }
                  alt="imageview-177"
                  src="/assets/Competitividad  - Square.png"
                />
              </section>
              <p
                className={styles["text-164-9212fdb9ae894dc6b40aaebbacf30562"]}
              >
                COMPETITIVIDAD
              </p>
              <p
                className={styles["text-165-e673dac373dd4fa6a2e9577e49adf8b9"]}
              >
                Servicios legales de la mejor calidad, adecuados para empresas
                pequeñas, medianas y grandes, así como para personas naturales y
                familias.
              </p>
            </section>
          </section>
        </section>
        <footer className={styles.nosotrosfooter}>
          <section
            className={styles["layer-481-8ea8d47ac0df41bd974edc6a3ade41ea"]}
          >
            <section
              className={styles["layer-95-be46962f2c7a4604a8db5697a1dccaa3"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-44-b1e4a4ccad6a49058c9e8d8274ecbe00"]
                  }
                  alt="imageview-44"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-45-b6d0f595e5fb4be9b82f14bbb5f204d4"]
                  }
                  alt="imageview-45"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-46-7365aa0eb1af4f7ab2276b19d4ae7a49"]
                  }
                  alt="imageview-46"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-47-74c50e2b301245b5987c5c72b30d8ce5"]
                  }
                  alt="imageview-47"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-96-0cf1699454a84b568873220e1390f61f"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={styles["text-81-43caecdf727b4996ad85b095a8116905"]}
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p className={styles["text-82-77addbf003584e37b65f343026a05ec9"]}>
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </footer>
      </main>
    );
  }

  return aguaRender();
};

export default Nosotros;
