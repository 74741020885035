import React from "react";
import { Link } from "react-router-dom";

import styles from "./williambolivar.module.css";

/**
 * Williambolivar component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Williambolivar component.
 */
const Williambolivar = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Williambolivar}>
        <section className={styles.williambolivarheader}>
          <Link to="/Home">
            <section
              className={styles["layer-252-ecf0be0d955f4c53979d6a7e1e83e7fb"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-440-6efe52b891f4430c9e94604157458381"]
                  }
                  alt="imageview-440"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-253-d4af2f1c8759416fbdc606064469bb21"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-312-6a450b41f863499390267bc521eedfa7"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-313-95023d46d4ce4e4dabfe1b160f6d0f9a"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-314-3f94d077ffbf470c87c02c160deb5eb6"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-315-a1300515531940cc9202b158df08c1d1"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-254-f0d67211411e43e4b7a38529f05f01aa"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-316-b60fe2a422dd40df95c9fa2965de7f7b"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-255-adbb14c54551470bb38d39fb6ffd7d28"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-15-442fd3f82e74454a9f29131268b95698"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section className={styles.williambolivarsection1}>
          <section
            className={styles["layer-2233-990dffa17047412390e33fa7dd4181f3"]}
          >
            <p className={styles["text-490-4104f362ee5c45ad9d95e6dbf11bac6a"]}>
              William Bolívar
            </p>
            <section
              className={styles["layer-666-d368f305cfa14c4f8cfa12c7a6e3ebca"]}
            >
              <img
                className={
                  styles["imageview-263-1caf787755944cb2b13eec1d7822edd8"]
                }
                alt="imageview-263"
                src="/assets/William Bolivar - Square.png"
              />
            </section>
            <p className={styles["text-265-8827ff0b010841f1afc0dbc475b3230e"]}>
              Laboral y Tributario
            </p>
            <p
              className={styles["text-266-d662d9f9be114a38ae8b0829383c63d0"]}
              onClick={() =>
                openInNewTab("mailto:william.bolivar@bavieralegal.com")
              }
            >
              william.bolivar@bavieralegal.com
            </p>
            <picture>
              <img
                className={
                  styles["imageview-113-a87d171cde4c4c30bf94df54efb9529f"]
                }
                alt="imageview-113"
                src="/assets/linkedin-Blue.png"
                onClick={() =>
                  openInNewTab(
                    "https://www.linkedin.com/in/william-bolivar-760a3b126/",
                  )
                }
              />
            </picture>
          </section>
        </section>
        <section className={styles.williambolivarsection2}>
          <section
            className={styles["layer-211-5d282100970249b389f3af1def440796"]}
          >
            <p className={styles["text-267-46fe0f5fdbea431db020277f3546faef"]}>
              William es contador público de la Universidad Piloto de Colombia,
              y cuenta con más de 20 años de experiencia profesional, durante
              los cuales ha participado en varios proyectos relacionados con
              asesoría tributaria y laboral.
            </p>
            <p className={styles["text-268-f5363facad1a42d4bb17209f76f9f498"]}>
              Ha participado en consultoría tributaria de expatriados,
              consultoría en seguridad social, atención de procesos de
              fiscalización ante autoridades como la DIAN y la UGPP, análisis
              económicos y acompañamiento en requerimientos oficiales de
              seguridad social, procesos persuasivos en aportes voluntarios,
              requerimientos de corrección declaraciones sobre impuestos sobre
              la renta frente a la deducibilidad de costos y gastos, Acción
              Persuasiva en Restitución de Aportes - Programas de Apoyo PAEF y
              PAP, diagnósticos laborales de nómina, prestaciones sociales,
              retención en la fuente, seguridad social y tributaria, entre
              otros.{" "}
            </p>
            <p className={styles["text-269-1d807ad072104b3ba2e0c4fc3e89aa4a"]}>
              Ha liderado el área de nómina electrónica en el último año
              asistiendo a compañías nacionales y multinacionales, en la
              definición de su estrategia en la implementación, codificación y
              diagnóstico en nómina electrónica.
            </p>
          </section>
        </section>
        <section
          className={styles["layer-1000-4f147b1c63dd466fb0ecb7e391415591"]}
        >
          <section
            className={styles["layer-1192-e5f7a9e4f0ae4926af34465dbb307a82"]}
          >
            <section
              className={styles["layer-1193-c1c18c20d3ed423791ea86e920750f25"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-381-0c118f8bc65c43289dec9dc017a419ad"]
                  }
                  alt="imageview-381"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-382-fe7415176336447f82885a5de5c908f0"]
                  }
                  alt="imageview-382"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-383-767c5b58ff6a44f49fbb88ba056a6370"]
                  }
                  alt="imageview-383"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-384-86ffe21bf4db400c803e76ef10c68340"]
                  }
                  alt="imageview-384"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-443-e156d5ed7ef3408f8c09af245d491f04"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-416-2dc0a32f493b4792bdf950d232f02093"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-417-4f0d52408020472e9a0168441cb075df"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Williambolivar;
