import React from "react";
import { Link } from "react-router-dom";

import styles from "./hernanpanesso.module.css";

/**
 * Hernanpanesso component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Hernanpanesso component.
 */
const Hernanpanesso = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Hernanpanesso}>
        <section
          className={styles["layer-256-69160b77ff7c4afcb6edc914850bd85d"]}
        >
          <Link to="/Home">
            <section
              className={styles["layer-312-cc1e1afe86624fbc98998cefd0faf46e"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-432-708956f1b6ac4a87b769da12ab4d3b3b"]
                  }
                  alt="imageview-432"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-313-4bc0bf400a204438bbde93b73cdecc9d"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-379-5d09288329174bac929613d6c9259fc8"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-380-e32bf2f7a69a45b3870b8f1336c30f9f"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-381-7923e1a88d514a67b287806e6adcad14"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-382-d568ae08286a4678a19cb8f87f892642"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-314-220552110e93443e826ee398a92e6766"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-383-b6820d9d8552467fa64ed36977c97ef4"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-315-66754efb781c466db105083f1545ef88"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-19-9dbef0ae1b7940dcbacaa30bb65ed0d0"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section
          className={styles["layer-257-f763afc56f2c4d81a6de4406a12a3d8f"]}
        >
          <section
            className={styles["layer-260-033360c0d0b744fe95e579f45c2bea0c"]}
          >
            <p className={styles["text-317-caab790961844a7cb3a8a2aea8fdedf5"]}>
              Hernán Panesso
            </p>
            <section
              className={styles["layer-705-9ce38905915947fc9183a2d3e0e7706a"]}
            >
              <img
                className={
                  styles["imageview-285-af2c2e3217ca47cd80fbecc12e341b7f"]
                }
                alt="imageview-285"
                src="/assets/Hernan Panesso - Square.png"
              />
            </section>
            <p className={styles["text-318-1d8b054d66ef40d58987d1f273966ed0"]}>
              Competencia y Datos Personales
            </p>
            <p
              className={styles["text-319-51f8df66e9224acc855ced6f925a4c2e"]}
              onClick={() =>
                openInNewTab("mailto:hernan.panesso@bavieralegal.com")
              }
            >
              hernan.panesso@bavieralegal.com
            </p>
            <picture>
              <img
                className={
                  styles["imageview-142-1b114baef3e04ccdbab392e60bd0e82f"]
                }
                alt="imageview-142"
                src="/assets/linkedin-Blue.png"
                onClick={() =>
                  openInNewTab(
                    "https://www.linkedin.com/in/hern%C3%A1n-antonio-panesso-mercado-20376a15/",
                  )
                }
              />
            </picture>
          </section>
        </section>
        <section
          className={styles["layer-258-07d7191375b6450c9b131e38a9604078"]}
        >
          <section
            className={styles["layer-261-2965aebb37bd419b8165cccfc0d40abd"]}
          >
            <p className={styles["text-320-2d4c4356a06546d1baca97e83859c492"]}>
              Hernán es abogado de la Universidad del Rosario, especialista en
              Derecho Económico y de Mercados de la misma Universidad, y
              magíster en Derecho de la Universidad de Oxford.
            </p>
            <p className={styles["text-321-5d1dd786bead4d9ba58bb74dbe140e98"]}>
              Hernán tiene experiencia trabajando en la Superintendencia de
              Industria y Comercio, y también asesorando clientes en procesos e
              investigaciones administrativas ante la misma entidad. Además, ha
              trabajado en firmas de abogados de primer nivel en Colombia,
              asesorando compañías nacionales y multinacionales en temas de
              competencia, protección del consumidor, protección de datos
              personales, integraciones empresariales, régimen de protección del
              espectro radioeléctrico, entre otros.
            </p>
            <p className={styles["text-322-4483735879fe455e98af2d361eecde1f"]}>
              Es docente en la Pontificia Universidad Javeriana, ha sido docente
              en la Universidad de la Sabana, conferencista para la Cámara de
              Comercio Colombo Americana, coautor de varios libros,
              publicaciones y cartillas, y ha sido reconocido como abogado
              destacado en Colombia por las publicaciones internacionales más
              prestigiosas como Legal 500, Chambers and Partners, entre otros.
            </p>
            <p className={styles["text-323-5e807978bf504a6e8e898821369b79fe"]}>
              Hernán habla español e inglés, ha publicado para Lexology, y ha
              sido consultado en calidad de experto por Asuntos Legales del
              Diario la República.
            </p>
          </section>
        </section>
        <section
          className={styles["layer-987-01e2a6dd33bb4d2c90c7e46bd5e126f3"]}
        >
          <section
            className={styles["layer-1235-342a37d10bbd4f498c7809595fd81863"]}
          >
            <section
              className={styles["layer-1236-5254a1e8601f4f77a1ca6dbb908bae35"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-406-59c1d92a66b84a88a1b6adb176721a18"]
                  }
                  alt="imageview-406"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-407-3919c7f0548e43d895085982a4cfbbd4"]
                  }
                  alt="imageview-407"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-408-729d550c0bd047f9ab196e58d8fa95f2"]
                  }
                  alt="imageview-408"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-409-ddb97bd61a374f9ab22d07dc118d92cb"]
                  }
                  alt="imageview-409"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-311-33c6fc8e43074b5d8addc1d24fbd5a4d"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-426-37124303ac624203b1164b37d76a00ab"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-427-b66ee6c3531845199d60796cb854a24b"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Hernanpanesso;
