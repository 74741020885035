import React from "react";
import { Link } from "react-router-dom";

import styles from "./juanuribe.module.css";

/**
 * Juanuribe component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Juanuribe component.
 */
const Juanuribe = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Juanuribe}>
        <section className={styles.juanuribeheader}>
          <Link to="/Home">
            <section
              className={styles["layer-417-d009988779ca475eb4fc461dd6e77381"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-400-2f842c6045ba42ae995be882b581134d"]
                  }
                  alt="imageview-400"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-418-eda976f682894247990ce7aee4929118"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-493-91215a6a12504d6c82d7e5d2d9909290"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-494-51edc3c53e6d462eb72b1b7488761945"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-495-db8292533abb401c8a61e9b1ff9f1c3e"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-496-84d7ca59da024322984d6266493e9cc3"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-419-378d0915e323439ab47ebbc856ba158b"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-497-bb4347cb29e94dbea8f1c4d7719b72d2"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-420-d8eea0bbe9bd45e69d4e1bd85808d848"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-28-047b0ca5fbc44e93bc71b40cd09def28"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section className={styles.juanuribesection1}>
          <section
            className={styles["layer-416-e80b2a621a5942079d957209576b2fbb"]}
          >
            <p className={styles["text-490-707f7c3f30aa4da7846183bb8e7d935d"]}>
              Juan Uribe
            </p>
            <section
              className={styles["layer-538-2884212a797240a9bf79cd8f19376366"]}
            >
              <img
                className={
                  styles["imageview-187-b3ccd1dc3dff4652b8360b31417fbd54"]
                }
                alt="imageview-187"
                src="/assets/Juan Uribe - Square.png"
              />
            </section>
            <p className={styles["text-491-a4aee9f2fd0b4562bb2b45edd91f43bd"]}>
              Laboral y Migratorio
            </p>
            <p
              className={styles["text-492-1221ce0c43124231b2cac20898da362d"]}
              onClick={() => openInNewTab("mailto:juan.uribe@bavieralegal.com")}
            >
              juan.uribe@bavieralegal.com
            </p>
            <picture>
              <img
                className={
                  styles["imageview-230-4965780287c74801a235d3ca3563ca66"]
                }
                alt="imageview-230"
                src="/assets/linkedin-Blue.png"
                onClick={() =>
                  openInNewTab(
                    "https://www.linkedin.com/in/juan-carlos-uribe-est%C3%A9vez-b97663b1/",
                  )
                }
              />
            </picture>
          </section>
        </section>
        <section className={styles.juanuribesection2}>
          <section
            className={styles["layer-421-754eb401f28e4a5e9a4a3a2b1916419b"]}
          >
            <p className={styles["text-498-8898d8110fb54d1694d2be667050fb42"]}>
              Juan Carlos es abogado de la Universidad de los Andes, con opción
              en administración de empresas de la misma Universidad. Es
              especialista en Derecho Laboral de la Pontificia Universidad
              Javeriana, y magíster en Derecho Laboral y Seguridad Social de la
              misma Universidad.
            </p>
            <p className={styles["text-499-7f70943f92a14ba9a0615ceda9992b44"]}>
              Dentro de su trayectoria profesional se encuentran firmas como
              Baker McKenzie, Posse Herrara Ruíz, Ernst & Young (EY) y
              Pricewaterhousecoopers (PwC).
            </p>
            <p className={styles["text-500-3be6da8393424528ab656f06503f8572"]}>
              Juan ha asistido compañías nacionales y multinacionales en
              procesos de debida diligencia laboral y de seguridad social, en
              consultoría laboral, en procesos de fiscalización ante la UGPP, en
              el análisis e identificación de contingencias laborales y de
              seguridad social, estructuras de compensación y beneficios,
              auditorías de nómina, procesos ante el Ministerio del Trabajo,
              terminaciones, transferencia de empleados, movilidad global, entre
              otros.
            </p>
            <p className={styles["text-501-de5bfd88c94c4fe08a7a7437eeb87e70"]}>
              Ha sido conferencista para la Asociación de Egresados de la
              Universidad de Los Andes, y ha dictado cursos de derecho laboral
              para equipos de recursos humanos de varios países. También, habla
              español e inglés, y ha publicado en Asuntos Legales del Diario la
              República.
            </p>
          </section>
        </section>
        <section
          className={styles["layer-911-244198e60ca04e619062d04e9d7d4825"]}
        >
          <section
            className={styles["layer-1349-a7cceab913fa4545a3df97e70191e510"]}
          >
            <section
              className={styles["layer-556-02b115e0bfb244908d03ac66bf855fed"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-479-bcae51498abe4266b0616ed866ec1bb9"]
                  }
                  alt="imageview-479"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-480-3eb41e58f0da4a0ead5367495a10ba24"]
                  }
                  alt="imageview-480"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-481-776ba4ac20da493c89e84d1a016257cb"]
                  }
                  alt="imageview-481"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-482-d59153fb553d4c1ca68f0f244bba1297"]
                  }
                  alt="imageview-482"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-557-9b9030d42074402fa32511d440225594"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-546-a480eba05fcf46b280c30573b0f0e0fb"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-547-0573eccbf03245078608f01710d2eef7"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Juanuribe;
