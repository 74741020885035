import React from "react";
import { Link } from "react-router-dom";
import IframeComponent from "../../components/iframeComponent/iframeComponent.js";

import styles from "./actualidad.module.css";

/**
 * Actualidad component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Actualidad component.
 */
const Actualidad = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Actualidad}>
        <section className={styles.actualidadheader}>
          <Link to="/Home">
            <section
              className={styles["layer-693-df5d043e51ee4706841b3e5abd03ac6c"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-265-4f3e9a9c329d4a6c8dbdfd26fd78cf5a"]
                  }
                  alt="imageview-265"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-694-4903a3cab5f9457a87652648453d7f4e"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-582-773b0bd783d24c17a3a9a94da8c61172"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-583-3e8f6d914391468a9c7afa711cc1b380"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-584-068d99dbd72043bcb3b3c9b47ea63384"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-585-73a80bbf66274c3081e06ee6718d681d"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-695-3dd8ab102b0b4bdbbc98a4cc131e1a6b"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-586-1b017a2da6ed4f93add0081017f40d53"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-696-31fc538627a84185b260a7677c35681b"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-25-9d210291c38f485ab6113f723a44ad57"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section className={styles.actualidadsection1}>
          <section
            className={styles["layer-598-6b7de485df9c4212aa48d256f9fab225"]}
          >
            <IframeComponent
              width={"100%"}
              height={"100%"}
              sourceURL={"https://www.instagram.com/bavieralegal/embed/"}
            />
          </section>
        </section>
        <section
          className={styles["layer-640-203a36235c934805ad0d205079eeea71"]}
        >
          <section
            className={styles["layer-564-d5538693a7724946b140868c56a51a74"]}
          >
            <section
              className={styles["layer-476-1f0b2118dd1f42f7b4a4ca72b4ef6c64"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-161-cef94b72e10b41b5bfb5230a981d03d5"]
                  }
                  alt="imageview-161"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-166-bd5054986ec94b469b7d354dcbb21edf"]
                  }
                  alt="imageview-166"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-163-2f040373153140cab1acf5834d37d14a"]
                  }
                  alt="imageview-163"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-164-ea85782686bd4898aacffa4a53937b62"]
                  }
                  alt="imageview-164"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-477-619a479db478480dbf1a568c3d312027"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-436-9c2f983ef0ad440a99217d10eb057c44"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-437-b7e14aeb75984b21920c49428a78ad87"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Actualidad;
