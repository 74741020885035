import React from "react";
import { Link } from "react-router-dom";

import styles from "./derechodelosnegocios.module.css";

/**
 * Derechodelosnegocios component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Derechodelosnegocios component.
 */
const Derechodelosnegocios = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Derechodelosnegocios}>
        <section className={styles.derechodelosnegociosheader}>
          <Link to="/Home">
            <section
              className={styles["layer-448-99725734ed1a4590b8b2fa466c2231dd"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-348-f8318da41233470abf2575305f42c71a"]
                  }
                  alt="imageview-348"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-449-bcb4419adbe746d3a8c9536ff9158594"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-392-98ed32b1bb7f410fb891d5c3730cfe1c"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-393-11a5e84432d44a8dac44e48094b7bf60"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-394-daf50a85e1744e88927473387a2c305e"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-395-9de0914284fe43c98d81b58f65e23049"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-450-a87b299feca54a9580754561145b86bd"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-396-df2c1d6f37ed4c059281eb0bce5e6c37"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-451-73d2cc46a4c84b759ace7594fbdc4687"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-16-0f05fb8a0c4c469785cbdf15a495b8a0"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section className={styles.derechodelosnegociossection1}>
          <p className={styles["text-467-0441bc4856f44527baf75ce10f3594d6"]}>
            DERECHO DE LOS NEGOCIOS
          </p>
        </section>
        <section
          className={`${styles["derechodelosnegociossection2-orientation"]} ${styles["derechodelosnegociossection2"]}`}
        >
          <section
            className={`${styles["layer-447-073737606d2b4fecb79409531b789fdc-orientation"]} ${styles["layer-447-073737606d2b4fecb79409531b789fdc"]}`}
          >
            <section
              className={styles["layer-551-582a383533554d38a3a58b8617030571"]}
            >
              <section
                className={styles["layer-553-266bb3031f2c4640968055f44fc7b70f"]}
              >
                <p
                  className={
                    styles["text-475-385b347606114a5fa8f60072950203d0"]
                  }
                >
                  1.
                </p>
              </section>
              <section
                className={styles["layer-554-279fb8306dc043e1a18398009bbb324b"]}
              >
                <p
                  className={
                    styles["text-474-5f03b531eca64e10b385767a5dd77b8b"]
                  }
                >
                  Constitución y liquidación de sociedades nacionales.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-552-e0751702ce2f4c42beeb861f1d52be52"]}
            >
              <section
                className={styles["layer-555-756aeae75299467ca7a3e427879995e3"]}
              >
                <p
                  className={
                    styles["text-473-996b11b0174547b086a5be8a4b69aaed"]
                  }
                >
                  2.
                </p>
              </section>
              <section
                className={styles["layer-556-34670f786c13442d9c1b85f1f5283ca6"]}
              >
                <p
                  className={
                    styles["text-472-774b5513577448d898f1a569dfa2f7e9"]
                  }
                >
                  Reformas estatutarias y acompañamiento legal en procesos de
                  adquisiciones, fusiones y escisiones de empresas.
                </p>
              </section>
            </section>
            <section
              className={styles["layer-557-47181be32de04aadb41e0943d50b721a"]}
            >
              <section
                className={styles["layer-558-560bad01bccd4dfeb5ed3c6275e96b2b"]}
              >
                <p
                  className={
                    styles["text-471-d4b5cc72836f4c168ee842142c43f0eb"]
                  }
                >
                  3.
                </p>
              </section>
              <section
                className={styles["layer-559-f914580563624d9189bb353e8f98d3a1"]}
              >
                <p
                  className={
                    styles["text-470-eb142efe1dde44b184474a71d876b1e4"]
                  }
                >
                  Acompañamiento legal en la creación y operación de empresas.
                </p>
              </section>
            </section>
          </section>
          <section
            className={styles["layer-550-85f962fe8a414d01a82086c5055e0893"]}
          >
            <section
              className={styles["layer-560-674430d4dcd948438cf7954112a7e634"]}
            >
              <section
                className={styles["layer-562-31742b8bb400402db8e5818a5bc53d16"]}
              >
                <p
                  className={
                    styles["text-469-495709f574ba43a099e6c0bbc1b0dc9b"]
                  }
                >
                  4.
                </p>
              </section>
              <section
                className={styles["layer-561-58c6f0c508f34f4abbea2f02f5bbd647"]}
              >
                <p
                  className={
                    styles["text-468-3a80bf5dc50e487d902281cd4ef2974f"]
                  }
                >
                  Elaboración de documentos legales.
                </p>
              </section>
            </section>
          </section>
        </section>
        <section
          className={styles["layer-704-84acdc2713a74cc6aa3a8f900934fc38"]}
        >
          <section
            className={styles["layer-831-1362ec1d01f24845bcd616ea0e42b25e"]}
          >
            <section
              className={styles["layer-1220-e5ac884349954747ae53ef043dd1b1be"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-285-1f7998ccf4944c8dbfe706a5ef8d29e7"]
                  }
                  alt="imageview-285"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-286-83a88a94714d4657be854097dfb8b8c0"]
                  }
                  alt="imageview-286"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-287-61aee76781bf4a4683060801f804fbb7"]
                  }
                  alt="imageview-287"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-288-d0162e402cf54815a2a052a0aa2c22ae"]
                  }
                  alt="imageview-288"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-1221-be33609c36c442c5a1782bc64b35be32"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-1068-636c63fbf5b6419ca362f5b84f14e24e"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-1069-6f57cab7946f4fa8807b8da66aa563e6"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Derechodelosnegocios;
