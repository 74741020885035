import React from "react";
import { Link } from "react-router-dom";
import IframeHTMLStringComponent from "../../components/iframeHTMLStringComponent/iframeHTMLStringComponent.js";

import styles from "./contacto.module.css";

/**
 * Contacto component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Contacto component.
 */
const Contacto = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Contacto}>
        <header className={styles.contactoheader}>
          <Link to="/Home">
            <section
              className={styles["layer-490-6b3dbfb499bd47f8a59679df18d04bdd"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-271-2eb5ba27b5b949d180ab2f8a8931905f"]
                  }
                  alt="imageview-271"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-491-a30e03c7fc224982b42317997406c92a"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-473-21d0a301c4f64562bc4391a191424903"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-474-99bb5c2144d944bdb8ae76b791d217a6"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-475-d52175800d624b0295c2081c47cf08f9"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-476-5f608a4db7e24a0cb3ab6488c07c6a61"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-492-d2060ba68a624221adefed806eec35b9"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-477-810bcbf98f4b42b7972d2fa648264f89"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-493-fbde1345fcf246cbb9ee7416287319cf"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-29-8896af37a1974abdbd485cfd223b1fe9"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </header>
        <section
          className={`${styles["contactosection1-orientation"]} ${styles["contactosection1"]}`}
        >
          <section
            className={styles["layer-2816-800a009efabf411fa6d39c2418f0daa9"]}
          >
            <p className={styles["text-2189-8fff9490f6a148159eca3d56983a6d68"]}>
              CONTACTO
            </p>
          </section>
        </section>
        <section
          className={`${styles["contactosection2-orientation"]} ${styles["contactosection2"]}`}
        >
          <section
            className={styles["layer-2467-d494000be63046a99715d0f87c6cc5c7"]}
          >
            <IframeHTMLStringComponent
              sourcedoc={
                '<html>   <head></head>   <body>  <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script> <script>   hbspt.forms.create({     region: "na1",     portalId: "44418093",     formId: "2705d69c-692d-4f0a-a89e-00e740e73f46"   }); </script>  </body> </html>'
              }
              width={"100%"}
              height={"100%"}
            />
          </section>
        </section>
        <footer className={styles.contactofooter}>
          <section
            className={styles["layer-574-6ff6732a49e04d69846ded0f69b05b0b"]}
          >
            <section
              className={styles["layer-453-cc430189b294462fa60cf28b3187edde"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-146-2e5bfdcff25a44f185532f2daab9f65f"]
                  }
                  alt="imageview-146"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-147-3fd4bd9036f94b069b7d08ecb80f6e94"]
                  }
                  alt="imageview-147"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-148-ab664a7a1f594b20a8f37d0fb3d21903"]
                  }
                  alt="imageview-148"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-149-8bdfb38be237401ea2fea9c08cf52806"]
                  }
                  alt="imageview-149"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-454-7c985bbeb2524bdebf0c9019c7d42858"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-412-800c11aa0eef4ed0a5318c5a6fd648e4"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-413-dbfb0bee793d46c9bb26572ccfe45487"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </footer>
      </main>
    );
  }

  return aguaRender();
};

export default Contacto;
