import React from "react";
import { Link } from "react-router-dom";

import styles from "./diegoduran.module.css";

/**
 * Diegoduran component.
 * @component
 * @type {React.FC}
 *
 * @returns {React.ReactElement} The Diegoduran component.
 */
const Diegoduran = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function aguaRender() {
    return (
      <main className={styles.Diegoduran}>
        <section className={styles.diegoduranheader}>
          <Link to="/Home">
            <section
              className={styles["layer-408-5ae1443e137a46d594d0958799c9be97"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-408-572e1caaa61c48da94e281ec0d6cb542"]
                  }
                  alt="imageview-408"
                  src="/assets/bavieraLogo.png"
                />
              </picture>
            </section>
          </Link>
          <section
            className={styles["layer-409-78292fe88fdc48f1856335473767da21"]}
          >
            <Link to="/Nosotros">
              <p
                className={styles["text-485-f12573a2f82e40e3940ea8477e2476e4"]}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/Equipo">
              <p
                className={styles["text-486-df482956286e4c3f8cb82d4728e3d4c7"]}
              >
                Equipo
              </p>
            </Link>
            <Link to="/Areasdepractica">
              <p
                className={styles["text-487-1874364637ed4ed0b7cd98386814ccd6"]}
              >
                Áreas de Práctica
              </p>
            </Link>
            <Link to="/Contacto">
              <p
                className={styles["text-488-ea74bebe27fd41b38deee0c4b9cd3ea9"]}
              >
                Contacto
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-410-13358e3df75644fcba840a2ddbc3673b"]}
          >
            <Link to="/Agendatucapactitacion">
              <p
                className={styles["text-489-d510bd65515d401699acbe142f0a3684"]}
              >
                Agenda tu Capacitación
              </p>
            </Link>
          </section>
          <section
            className={styles["layer-411-69d985ac8907423195b7b3b251269064"]}
          >
            <Link to="/Mobilemenu">
              <span
                className={styles["icon-27-8e4087d129c94584af0d01b42be5798b"]}
              >
                density_medium
              </span>
            </Link>
          </section>
        </section>
        <section className={styles.diegoduransection1}>
          <section
            className={styles["layer-404-6c2c5df5ba364c2b8c61194658885176"]}
          >
            <p className={styles["text-476-be69d26650844c90972fc5e23245466a"]}>
              Diego Felipe Durán
            </p>
            <section
              className={styles["layer-525-abcaf23a75344645add1b6b0b86fffef"]}
            >
              <img
                className={
                  styles["imageview-179-8d2806a41b1a4d2999611897646942fa"]
                }
                alt="imageview-179"
                src="/assets/Diego Felipe Duran - Square.png"
              />
            </section>
            <p className={styles["text-477-dc0f3843792a41c892ccc986ca50b885"]}>
              Laboral y Migratorio
            </p>
            <p
              className={styles["text-478-4148cf31d519482183405fdb74ee8f81"]}
              onClick={() =>
                openInNewTab("mailto:felipe.duran@bavieralegal.com")
              }
            >
              felipe.duran@bavieralegal.com
            </p>
            <picture>
              <img
                className={
                  styles["imageview-223-863db6b5630a4116b8cba29d047ca4b1"]
                }
                alt="imageview-223"
                src="/assets/linkedin-Blue.png"
                onClick={() =>
                  openInNewTab(
                    "https://www.linkedin.com/in/diego-felipe-dur%C3%A1n-t%C3%A9llez-2a4766148/",
                  )
                }
              />
            </picture>
          </section>
        </section>
        <section className={styles.diegoduransection2}>
          <section
            className={styles["layer-405-87007fae8488423baafe60f20a949db8"]}
          >
            <p className={styles["text-479-9b9b04b872724cb08043a1650107c03b"]}>
              Diego Felipe es abogado de la Pontificia Universidad Javeriana. Es
              especialista en Derecho Laboral, y magíster en Derecho Laboral y
              Seguridad Social de la misma Universidad.
            </p>
            <p className={styles["text-480-5d5bf0b3c12a4fb5878e3025432628b6"]}>
              Dentro de su trayectoria profesional se encuentran firmas como DLA
              Piper Martínez Beltrán y Philippi Prietocarrizosa Ferrero Du &
              Uría.
            </p>
            <p className={styles["text-481-3d56ebc07e5a4592804a5c48ae50bf55"]}>
              Ha asistido compañías nacionales y multinacionales en la
              elaboración y ejecución de planes de retiro voluntario,
              implementación de planes de beneficios extralegales, procesos de
              debida diligencia laboral y de seguridad social así como litigios
              judiciales y administrativos ante la UGPP, elaboración y ejecución
              de las políticas generales de recursos humanos, estructuración de
              comités de convivencia laboral y comités paritarios de seguridad y
              salud en el trabajo, entre otros.
            </p>
            <p className={styles["text-482-cc4f030200084665b0f8c5c79c1581d4"]}>
              Diego Felipe ha sido reconocido por la publicación internacional
              The Legal 500. También, habla español e inglés y ha publicado en
              Asuntos Legales del Diario la República.
            </p>
          </section>
        </section>
        <section
          className={styles["layer-924-0a0a75c6bb1c4dfea48f2c9f19eeaced"]}
        >
          <section
            className={styles["layer-582-c134d5bfa6014a41a9ebc014dde94fa9"]}
          >
            <section
              className={styles["layer-544-fe5e5912daa043f0ba86594b393be852"]}
            >
              <picture>
                <img
                  className={
                    styles["imageview-467-d4f78f37369042c6bdf2a82b93ddf306"]
                  }
                  alt="imageview-467"
                  src="/assets/linkedin-Black.png"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/company/baviera-legal/",
                    )
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-468-86d1784a2b6f46e19b152fab36635d1d"]
                  }
                  alt="imageview-468"
                  src="/assets/instagram.png"
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/bavieralegal/")
                  }
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-469-d2d13a56b857463aa4455615b15d3994"]
                  }
                  alt="imageview-469"
                  src="/assets/email.png"
                  onClick={() => openInNewTab("mailto:info@bavieralegal.com")}
                />
              </picture>
              <picture>
                <img
                  className={
                    styles["imageview-470-e7588ce0d34b4412bd5946df7b8e9abe"]
                  }
                  alt="imageview-470"
                  src="/assets/llamada-telefonica.png"
                  onClick={() => openInNewTab("https://wa.me/573107749255")}
                />
              </picture>
            </section>
            <section
              className={styles["layer-545-014d1353b7034183a8a0607965fb448e"]}
            >
              <Link to="/Politicadetratamientodedatos">
                <p
                  className={
                    styles["text-532-400ac49e52f0474a8719bcdc1b7e9a27"]
                  }
                >
                  Política de tratamiento de datos
                </p>
              </Link>
              <p
                className={styles["text-533-d702a5784ae44774a20f41463c6ac1e4"]}
              >
                Copyrights 2023 © Baviera Legal
              </p>
            </section>
          </section>
        </section>
      </main>
    );
  }

  return aguaRender();
};

export default Diegoduran;
